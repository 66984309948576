import Typography from "@material-ui/core/Typography";
import Layout from "../Layout/Layout";
import React from "react";
import VideoUploader from "../../components/Upload/VideoUploader";

const Upload = () => {
  return (<Layout>
    <Typography variant="h1" component="h1" gutterBottom>Upload</Typography>
    <p style={{marginBottom: '30px'}}>Hier kannst du ein neues Video auf FamilyTube einstellen.</p>
    <VideoUploader accept={['video/*']} />
  </Layout>);
}

export default Upload;
