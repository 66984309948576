import React from 'react';
import PlayIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import ControlIcon from "./ControlIcon";
import './PlayPauseButton.scss';

const PlayPauseButton = ({isPaused, togglePlayPause}) => {
    if (isPaused) {
        return (<div className="PlayButton"><ControlIcon clickHandler={togglePlayPause} aria-label="Play"><PlayIcon /></ControlIcon></div>);
    }
    return (<div className="PauseButton"><ControlIcon clickHandler={togglePlayPause} aria-label="Play"><PauseIcon /></ControlIcon></div>);
};

export default PlayPauseButton;
