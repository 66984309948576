import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
export default function ConfirmDeleteDialog(props) {
  const { onClose, value: valueProp, open, dialogContent, ...other } = props;

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">Wirklich Löschen?</DialogTitle>
      <DialogContent dividers>
        {dialogContent}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="secondary">
          Abbrechen
        </Button>
        <Button onClick={handleOk} style={{color: '#ff0000'}}>
          Löschen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

