import React, {forwardRef} from 'react';
import DeleteIcon from "@material-ui/icons/Delete";
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import {OpenWith} from "@material-ui/icons";

export const Photo = forwardRef(({url, index, text, faded, style, isPreview, ...props}, ref) => {
  const inlineStyles = {
    opacity: faded ? '0.2' : '1',
    transformOrigin: '0 0',
    height: 200,
    position: 'relative',
    background: 'rgba(0,0,0,0.3)',
    ...style,
  };

  return <div style={inlineStyles}>
    <div ref={ref} style={{width: '100%', height: '100%'}}>
      <div style={{display: "flex", alignItems: 'center', margin: '5px 0 0 5px', minWidth: '100%', color: '#fff', position: 'absolute', zIndex: 2}}>
        <div {...props} style={{cursor: 'move'}} title="Position ändern durch ziehen"><OpenWith /></div>
        <div onClick={() => {props.onDelete(props.id)}} style={{cursor: 'pointer'}} title="Löschen"><DeleteIcon /></div>
        <div onClick={() => {props.onSelectPrimary(props.id)}} style={{cursor: 'pointer'}} title={'Primärbild: '+ (isPreview ? 'Ja' : 'Nein')}><WallpaperIcon style={{color: isPreview ? 'red' : '#fff'}} /></div>
      </div>
      <div style={{width: '100%', height: '100%', display: "flex", alignItems: 'center', justifyContent: 'center', position: "absolute", top: 0, left: 0, zIndex: 1}}>
        <img src={url} alt="" style={{maxWidth: '100%', maxHeight: '100%'}}/>
        <input type="text" placeholder="Titel vergeben..." value={text} onChange={(e) => {props.onChangeText(props.id, e.target.value)}} style={{background: 'rgba(255,255,255,0.6)', color: '#333', height: '24px', outline: 'none', border: '1px solid #333', position: 'absolute', bottom: 0, width: "100%", left: 0, display: props.hideText ? 'none' : 'inline'}} />
      </div>
    </div>
  </div>;
});
