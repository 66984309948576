import React, { Component, createRef } from 'react';
import './Scrubber.scss';

const clamp = (min, max, val) => Math.min(Math.max(min, val), max);
const round = (val, dp) => parseFloat(val.toFixed(dp));

class Scrubber extends Component {
    barRef = createRef();

    state = {
        seeking: false,
        mouseX: null,
        mouseY: null,
        touchId: null,
        touchX: null,
        touchY: null,
        hover: false,
    };

    componentDidMount() {
        window.addEventListener('mousemove', this.handleMouseMove);
        window.addEventListener('mouseup', this.handleSeekEnd);
        window.addEventListener('touchmove', this.handleTouchMove);
        window.addEventListener('touchend', this.handleTouchEnd);
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.handleMouseMove);
        window.removeEventListener('mouseup', this.handleSeekEnd);
        window.removeEventListener('touchmove', this.handleTouchMove);
        window.removeEventListener('touchend', this.handleTouchEnd);
    }

    getPositionFromCursor = () => {
        const barDomNode = this.barRef.current;
        if (!barDomNode) {
            return 0;
        }
        const { min, max } = this.props;
        const { mouseX, touchX } = this.state;
        const { left, width } = barDomNode.getBoundingClientRect();
        const cursor = typeof touchX === 'number' ? touchX : mouseX || 0;
        const clamped = clamp(left, left + width, cursor);
        const decimal = round((clamped - left) / width, 7);
        return round((max - min) * decimal, 7);
    };

    handleMouseMove = (e) => {
        this.setState({ mouseX: e.pageX, mouseY: e.pageY }, () => {
            if (this.state.seeking && this.props.onScrubChange) {
                this.props.onScrubChange(this.getPositionFromCursor());
            }
            if (this.state.hover && this.props.onSeekPreview) {
                this.props.onSeekPreview(this.getPositionFromCursor());
            }
        });
    };

    handleTouchMove = (e) => {
        if (this.state.seeking) {
            e.preventDefault();
        }

        const touch = Array.from(e.changedTouches).find(t => t.identifier === this.state.touchId);
        if (touch) {
            this.setState({ touchX: touch.pageX, touchY: touch.pageY }, () => {
                if (this.state.seeking && this.props.onScrubChange) {
                    this.props.onScrubChange(this.getPositionFromCursor());
                }
            });
        }
    };

    handleSeekStart = (e) => {
        this.setState({ seeking: true, mouseX: e.pageX, mouseY: e.pageY }, () => {
            if (this.props.onScrubStart) {
                this.props.onScrubStart(this.getPositionFromCursor());
            }
        });
    };

    handleTouchStart = (e) => {
        const touch = e.changedTouches[0];
        this.setState({ hover: true, seeking: true, touchId: touch.identifier, touchX: touch.pageX, touchY: touch.pageY }, () => {
            if (this.props.onScrubStart) {
                this.props.onScrubStart(this.getPositionFromCursor());
            }
        });
    };

    handleSeekEnd = () => {
        if (this.state.seeking) {
            if (this.props.onScrubEnd) {
                this.props.onScrubEnd(this.getPositionFromCursor());
            }
            this.setState({ seeking: false, mouseX: null, mouseY: null  });
        }
    };

    handleTouchEnd = (e) => {
        const touch = Array.from(e.changedTouches).find(t => t.identifier === this.state.touchId);
        if (touch && this.state.seeking) {
            if (this.props.onScrubEnd) {
                this.props.onScrubEnd(this.getPositionFromCursor());
            }
            this.setState({ hover: false, seeking: false, touchX: null, touchY: null, touchId: null });
        }
    };

    renderMarkers = () => {
        const { markers } = this.props;
        if (markers) {
            return markers.map((value) => {
                    const valuePercent = this.getValuePercent(value.percentage);
                    return <div key={value.name} className="Scrubber__bar__marker" style={{left: `${valuePercent}%`}} />
                }
            );
        }
        return null;
    };

    getValuePercent = (value) => {
        const { min, max } = this.props;
        return ((clamp(min, max, value) / (max - min)) * 100).toFixed(5);
    };

    render() {
        const { value, bufferPosition = 0 } = this.props;
        const valuePercent = this.getValuePercent(value);
        const bufferPercent = this.getValuePercent(bufferPosition);

        const classes = ['Scrubber'];
        if (this.state.hover) classes.push('Scrubber--hover');
        if (this.state.seeking) classes.push('Scrubber--seeking');

        return (
            <div
                onMouseDown={this.handleSeekStart}
                onTouchStart={this.handleTouchStart}
                onTouchEnd={e => e.preventDefault()}
                onMouseOver={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false }, this.props.mouseLeaveHandler)}
                className={classes.join(' ')}
            >
                <div className="Scrubber__bar" ref={this.barRef}>
                    <div className="Scrubber__bar__buffer" style={{ width: `${bufferPercent}%` }} />
                    {this.renderMarkers()}
                    <div className="Scrubber__bar__progress" style={{ width: `${valuePercent}%` }} />
                    <div className="Scrubber__bar__thumb" style={{ left: `${valuePercent}%` }} />
                </div>
            </div>
        );
    }
}

export default Scrubber;