import ajax from '../../ajax/ajax';
import { Base64 } from 'js-base64';

const decodeTokenPayload = (token) => {
    const parts = token.split('.');
    const payload = Base64.decode(parts[1]);

    return JSON.parse(payload);
};

const authStart = () => {
    return {
        type: 'AUTH_START'
    }
};

const authError = (error) => {
    return {
        type: 'AUTH_ERROR',
        error: error
    }
};

const authSuccess = (token, username, roles) => {
    localStorage.setItem('auth.token', token);

    return {
        type: 'AUTH_SUCCESS',
        token: token,
        userName: username,
        roles: roles
    }
};

const login = (email, password) => {
    return (dispatch) => {
        dispatch(authStart());
        ajax.post('/api/login', {
            username: email,
            password: password
        }, (response) => {
            const payload = decodeTokenPayload(response.token);
            dispatch(authSuccess(response.token, payload['name'], payload['roles']));
        }, (error, status) => {
            if (status === 401) {
                dispatch(authError('E-Mail / Passwort falsch.'));
            } else {
                dispatch(authError('Server nicht erreichbar.'));
            }
        });
    }
};

const check = () => {
    const token = localStorage.getItem('auth.token');
    if (token) {
        const payload = decodeTokenPayload(token);
        if (payload['exp'] > Date.now() / 1000) {
            return {
                type: 'AUTH_SUCCESS',
                token: token,
                userName: payload['name'],
                roles: payload['roles']
            }
        }
    }

    return {
        type: 'AUTH_CLEAR'
    }
};

export {login, check};
