import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Layout from "../Layout/Layout";
import Message from "../../components/UI/Message/Message";
import Spinner from "../../components/UI/Spinner/Spinner";
import {Snackbar} from "@material-ui/core";
import ajax, {getAjaxConfig, getToken} from "../../ajax/ajax";
import './EditVideo.scss';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Info from "../../components/UI/forms/Guide/Info";
import UpdatedPlayer from "../../components/Video/UpdatedPlayer/UpdatedPlayer";
import MarkerList from "../../components/Video/Marker/MarkerList";
import {Alert} from "@material-ui/lab";
import VideoOrGalleryDetails from "../../components/Upload/VideoOrGalleryDetails";
import {defaultForm} from "../../components/Upload/VideoUploader";

const EditVideo = () => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [form, setForm] = useState(defaultForm);
  const [published, setPublished] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [currentPlayHeadTime, setCurrentPlayHeadTime] = useState(0);
  const [seekTo, setSeekTo] = useState(null);
  const [currentThumbnailUrl, setCurrentThumbnailUrl] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const {videoId} = useParams();

  useEffect(() => {
    if (!videoId) {
      return;
    }
    const {cancel} = ajax.get(`/api/v1/upload/video/${videoId}`, (data) => {
      setForm({
        name: {error: null, value: data.video.title},
        year: {error: null, value: data.video.year},
        description: {error: null, value: data.video.description},
        people: {error: null, value: data.video.people},
        places: {error: null, value: data.video.places},
        categories: {error: null, value: data.video.categories},
        tags: {error: null, value: data.video.tags},
        markers: {error: null, value: data.video.markers},
        thumbnailTime: {error: null, value: data.video.thumbnailTime},
      });
      setPublished(data.video.published === 1);
      setVideoUrl(data.video.published === 1 ? data.video.streamUrl : null);
      setCurrentThumbnailUrl(data.video.thumbnailUrl + '&token='+encodeURIComponent(getToken()));

    }, (xhrStatusText) => {
      setError(JSON.parse(xhrStatusText).message);
    });

    return () => {
      cancel();
    }
  }, [videoId]);

  useEffect(() => {
    if (submitting) {
      const data = {};
      for (const fieldName of Object.keys(form)) {
        data[fieldName] = form[fieldName].value;
      }
      ajax.post(`/api/v1/upload/video/${videoId}`, data, (data) => {
        if (!data.success) {
          setError(data.message);
          setSubmitting(false);
          return;
        }
        setSuccessMessage('Erfolgreich gespeichert.')
        setError(null);
        setSubmitting(false);
      }, (xhrStatusText, xhrStatus, xhr) => {
        setError(JSON.parse(xhr.responseText).message);
        setSubmitting(false);
      });
    }
  }, [submitting, videoId, form]);

  function updateForm(name, value) {
    setForm((prevState) => {
      return {...prevState, [name]: {...prevState[name], value: value}};
    });
  }

  function addMarker(name, time) {
    const offendingMarker = form.markers.value.find((marker) => {
      return marker.time === time;
    });
    if (offendingMarker) {
      const newForm = {...form, markers: {...form.markers, error: `Zu Nahe an Kapitelmarke ${offendingMarker.name}`}};
      setForm(newForm);
      return false;
    }
    const value = [...form.markers.value, {name: name, time: time}];
    value.sort((a, b) => {
      return a.time - b.time;
    });
    updateForm('markers', value);
    return true;
  }

  function updateMarker(marker, name, time) {
    const updatedMarkerIndex = form.markers.value.findIndex((candidate) => {
      return candidate === marker;
    });
    if (updatedMarkerIndex > -1) {
      const offendingMarker = form.markers.value.find((candidate) => {
        return candidate.time === time && candidate !== form.markers.value[updatedMarkerIndex];
      });
      if (offendingMarker) {
        const newForm = {...form, markers: {...form.markers, error: `Zu Nahe an Kapitelmarke ${offendingMarker.name}`}};
        setForm(newForm);
        return false;
      }

      const newMarkers = [...form.markers.value];
      newMarkers[updatedMarkerIndex] = {name, time};

      const newForm = {...form, markers: {value: newMarkers, error: null}};
      setForm(newForm);
      return true;
    }

    const newForm = {...form, markers: {...form.markers, error: `Marker fehlt.`}};
    setForm(newForm);
    return false;
  }

  function deleteMarker(marker) {
    const value = [...form.markers.value.filter((value) => {
      return marker !== value;
    })];
    value.sort((a, b) => {
      return a.time - b.time;
    });
    updateForm('markers', value);
  }

  function resetMarkerError() {
    const newForm = {...form, markers: {...form.markers, error: null}};
    setForm(newForm);
  }

  if (null !== error) {
    return (<Layout>{<Message type="error">{error.toString()}</Message>}</Layout>);
  }

  let content = (<Spinner/>);
  if (null !== form) {
    content = (
      <div className="EditVideo">
        <Typography variant="h1" component="h1" gutterBottom>Video bearbeiten</Typography>
        <p style={{marginBottom: '30px'}}>Hier kannst du die Details deines Videos bearbeiten.</p>
        <form style={{marginBottom: '30px'}}>
          <VideoOrGalleryDetails form={form} setForm={setForm} fieldsDisabled={submitting} nameFieldTitle="Titel des Videos" />

          {!published ? (<div style={{marginBottom: '30px'}}><Info highlight>Dein Video wird noch verarbeitet. Du kannst Vorschaubild und Kapitelmarken erst nach der Verarbeitung bearbeiten.</Info></div>) : (
            <React.Fragment>
              <div className="EditVideo__player">
                <div className="EditVideo__player__video">
                  <UpdatedPlayer url={videoUrl} autoPlay={false} onPlayHeadTimeChanged={(time) => {setCurrentPlayHeadTime(time);}} markers={Array.isArray(form.markers.value) ? form.markers.value : []} seekTo={seekTo} showControlsOnlyOnHover={false} videoId={videoId} showOverlayAfterPlaybackEnded={false}/>
                </div>
                <div className="EditVideo__player__markers">
                  <MarkerList makers={form.markers.value} markerError={form.markers.error} addMarkerCallback={(name) => {return addMarker(name, currentPlayHeadTime);}} updateMarker={(marker, name) => {return updateMarker(marker, name, currentPlayHeadTime);}} deleteMarkerCallback={deleteMarker} setSeekTo={setSeekTo} resetMarkerError={resetMarkerError} />
                  <div style={{marginTop: '20px'}}>
                    <Typography variant="h3" component="h3" gutterBottom>Vorschaubild</Typography>
                    {currentThumbnailUrl ? (<img src={currentThumbnailUrl} alt="" style={{border:'1px solid #ccc'}} />) : null}
                    <p style={{fontSize: '12px', margin: '2px 0 5px 0'}}>Aktuelle Position im Video als Vorschaubild setzen:</p>
                    <Button variant="contained" size="small" onClick={() => {setCurrentThumbnailUrl(`${getAjaxConfig().baseUrl}/api/v1/upload/video/${videoId}/thumbnail?time=${currentPlayHeadTime}&token=${getToken()}`); updateForm('thumbnailTime', currentPlayHeadTime)}}>Vorschaubild setzen</Button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}

          <Button color="primary" variant="contained" size="large" onClick={() => {setSubmitting(true)}} disabled={submitting}>Speichern</Button>
        </form>
      </div>
    );
  }

  return (
    <Layout>
      {content}
      {successMessage ? (<Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => {setSuccessMessage(null);}}>
        <Alert onClose={() => {setSuccessMessage(null);}} severity="success">{successMessage}</Alert>
      </Snackbar>) : null}
    </Layout>
  );

}

export default EditVideo;
