import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import ajax, {getToken} from "../../ajax/ajax";
import Layout from "../Layout/Layout";
import Message from "../../components/UI/Message/Message";
import Spinner from "../../components/UI/Spinner/Spinner";
import './MyGalleries.scss';
import Button from "@material-ui/core/Button";
import GalleryCard from "../../components/Upload/GalleryCard";

const MyGalleries = () => {
  const [galleries, setGalleries] = useState(null);
  const [error, setError] = useState(null);
  const [galleryToDelete, setGalleryToDelete] = useState(null);

  useEffect(() => {
    const cancelRequest = ajax.get('/api/v1/upload/galleries', (data) => {
      setGalleries(data.galleries);
    }, (err) => {
      setError(err);
    }).cancel;

    return () => {
      if (cancelRequest) {
        cancelRequest();
      }
    };
  }, []);

  useEffect(() => {
    if (null === galleryToDelete) {
      return;
    }
    const cancelRequest = ajax.delete(`/api/v1/upload/gallery/${galleryToDelete}`, (data) => {
      setGalleries((prevState) => {
        const newState = [...prevState];
        return newState.filter((candidate) => {
          return galleryToDelete !== candidate.id;
        });
      });
      setGalleryToDelete(null);
    }, (err) => {
      setError('Galerie konnte nicht gelöscht werden.');
    }).cancel;

    return () => {
      if (cancelRequest) {
        cancelRequest();
      }
    };
  }, [galleryToDelete]);

  function deleteGallery(id) {
    setGalleryToDelete(id);
  }

  let content = null;
  if (null !== error) {
    content = (<Message type="error">{error.toString()}</Message>);
  } else if (!galleries) {
    content = (<Spinner />);
  } else if (galleries.length === 0) {
    content = (
      <React.Fragment>
        <Typography variant="h1" component="h1" gutterBottom>Deine Galerien</Typography>
        <p style={{fontSize: '20px'}}>Du hast noch keine Galerien hochgeladen!</p>
        <Button variant="contained" color="primary" component="a" href="/uploadGallery">Lade deine erste Galerie hoch</Button>
      </React.Fragment>
    );
  } else {
    content = (
      <div className="MyGalleries">
        <Typography variant="h1" component="h1" gutterBottom>Deine Galerien</Typography>
        <p style={{marginBottom: '25px'}}>Hier siehst du eine Übersicht deiner Galerien.</p>
        <Button variant="contained" color="secondary" component="a" href="/uploadGallery" style={{marginBottom: '40px'}}>Neue Galerie hochladen</Button>
        {galleries.length > 0 ? (
          <div className="MyGalleries__galleries">
            {galleries.map((gallery) => {
              return (<GalleryCard key={gallery.id} id={gallery.id} date={gallery.created} description={gallery.description} title={gallery.title} thumbnailUrl={gallery.thumbnailUrl + '?token='+encodeURIComponent(getToken())} onDeleteGallery={deleteGallery} />);
            })}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <Layout>
      {content}
    </Layout>
  );
}

export default MyGalleries;
