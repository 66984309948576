import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {useParams} from "react-router-dom";
import ajax, {ajaxConfig} from "../../ajax/ajax";
import Layout from "../Layout/Layout";
import VideoOrGalleryDetails from "../../components/Upload/VideoOrGalleryDetails";
import Spinner from "../../components/UI/Spinner/Spinner";
import SortableGrid from "../../components/SortableGrid/SortableGrid";
import Button from "@material-ui/core/Button";
import {v4 as uuid} from "uuid";
import axios from "axios";
import Message from "../../components/UI/Message/Message";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

const EditGallery = () => {
  const [uploads, setUploads] = useState({});
  const [form, setForm] = useState(null);
  const [images, setImages] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const {galleryId} = useParams();

  useEffect(() => {
    if (!galleryId) {
      return;
    }
    const {cancel} = ajax.get(`/api/v1/upload/gallery/${galleryId}`, (data) => {
      setForm({
        name: {error: null, value: data.gallery.title},
        year: {error: null, value: data.gallery.year},
        description: {error: null, value: data.gallery.description},
        people: {error: null, value: data.gallery.people},
        places: {error: null, value: data.gallery.places},
        categories: {error: null, value: data.gallery.categories},
        tags: {error: null, value: data.gallery.tags},
      });
      setImages(data.gallery.images.map((image) => {
        return {
          id: image.id,
          databaseId: image.id,
          isPreview: image.isPreview,
          fileName: image.fileName,
          thumbnail: image.small,
          text: image.text,
        };
      }));
    }, (xhrStatusText) => {
      setError(JSON.parse(xhrStatusText).message);
    });

    return () => {
      cancel();
    }
  }, [galleryId]);

  useEffect(() => {
    if (!submitting) {
      return;
    }
    setSuccessMessage(null);
    ajax.post('/api/v1/gallery/'+galleryId, {
      images: images.map((item) => {
        return {
          id: item.databaseId,
          title: '',
          isPreview: item.isPreview,
          text: item.text,
        }
      }),
      name: form.name.value,
      year: form.year.value,
      description: form.description.value,
      people: form.people.value,
      places: form.places.value,
      categories: form.categories.value,
      tags: form.tags.value,
    }, (data) => {
      setSubmitting(false);
      setError(null);
      setUploads({});
      setSuccessMessage('Erfolgreich gespeichert.')
    }, (statusText) => {
      setSubmitting(false);
      setError(JSON.parse(statusText).message);
    });
  }, [submitting]);

  let uploadHandlerQueueRunning = false;
  const uploadHandlerQueue = [];
  function handleQueue() {
    if (uploadHandlerQueueRunning) {
      return;
    }
    if (uploadHandlerQueue.length === 0) {
      return;
    }
    uploadHandlerQueueRunning = true;
    const handlerFunction = uploadHandlerQueue.shift();
    handlerFunction().then(() => {
      uploadHandlerQueueRunning = false;
      handleQueue();
    }).catch(() => {
      uploadHandlerQueueRunning = false;
      handleQueue();
    });
  }

  function handleFileEvent(e) {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    chosenFiles.forEach((file) => {
      const localId = uuid();
      setUploads((oldState) => {
        const newState = {...oldState};
        newState[localId] = {file: file, id: localId, progress: 0, name: file.name, error: null};

        return newState;
      });

      const handlerFunction = () => {
        return new Promise((resolve, reject) => {
          axios.post(
            `${ajaxConfig.baseUrl}/api/v1/upload/photo`,
            file,
            {
              params: {
                fileName: file.name,
              },
              headers: { "Content-Type": "application/octet-stream", "x-auth-token": ajaxConfig.token },
              onUploadProgress: (progressEvent) => {
                setUploads((old) => {
                  return {...old, [localId]: {...old[localId], progress: progressEvent.progress * 100}};
                });
              },
            }
          ).then((response) => {
            if (response.data.success) {
              const uploadedItem = {
                id: localId,
                databaseId: response.data.id,
                isPreview: false,
                fileName: file.name,
                thumbnail: response.data.thumbnail,
              };
              setImages((oldState) => {
                const newState = [...oldState];
                newState.push(uploadedItem);
                return newState;
              });
              setUploads((old) => {
                const newState = {...old};
                delete newState[localId];
                return newState;
              });
            }
            resolve();
          }).catch((err) => {
            setUploads((old) => {
              return {...old, [localId]: {...old[localId], error: err.response.data.message}};
            });
            reject();
          });
        });
      };
      uploadHandlerQueue.push(handlerFunction);
      handleQueue();
    });
  }

  function removeUploadingItem(localId) {
    setUploads((old) => {
      const newState = {...old};
      delete newState[localId];
      return newState;
    });
  }

  let content = (<Spinner/>);
  if (null !== form) {
    content = (
      <div className="EditGallery">
        <Typography variant="h1" component="h1" gutterBottom>Galerie bearbeiten</Typography>
        <form>
          <VideoOrGalleryDetails form={form} setForm={setForm} fieldsDisabled={submitting} nameFieldTitle="Name der Galerie" />
          <input id='fileUpload' type='file' multiple accept="image/*" style={{display: 'none'}} onChange={handleFileEvent} />
          <label htmlFor='fileUpload'>
            <Button variant="contained" color="secondary" size="medium" component="span" style={{marginBottom: '20px'}}>Weitere Bilder hinzufügen</Button>
          </label>
          {Object.values(uploads).map((item) => {
            return (<div key={item.id}>
              {item.error ? (<Message type="error" close={() => {removeUploadingItem(item.id);}}>{item.name}: {item.error}</Message>) : (<Paper elevation={1} style={{padding: '1em', marginBottom: '10px'}}><div>Hochladen von {item.name}</div><LinearProgress value={item.progress} variant="determinate" /></Paper>)}
            </div>);
          })}
          <SortableGrid items={images} setItems={setImages} />
          {error ? (<Message type="error">{error}</Message>) : null}
          <div>
            <Button variant="contained" color="primary" size="large" onClick={() => {setSubmitting(true);}} style={{marginTop: '15px'}} disabled={submitting}>Speichern</Button>
          </div>
        </form>
        {successMessage ? (<Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => {setSuccessMessage(null);}}>
          <Alert onClose={() => {setSuccessMessage(null);}} severity="success">{successMessage}</Alert>
        </Snackbar>) : null}
      </div>
    );
  }

  return (
    <Layout>
      {content}
    </Layout>
  );
}

export default EditGallery;
