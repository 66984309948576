import React, {useEffect, useState} from 'react';
import Layout from '../Layout/Layout';
import Filter from '../../components/Filter/Filter';
import {useLocation, useHistory} from 'react-router-dom';
import VideoList from '../../components/VideoList/VideoList';
import useVideos from '../../hooks/useVideos';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import './Videos.scss';
import {isMobileOrTablet} from "../../util/breakpoints";
import FilterListIcon from '@material-ui/icons/FilterList';

const Videos = () => {
    const location = useLocation();
    const queryString = location.search;
    const [videos, setQueryString] = useVideos(queryString);
    const [sidebarShown, setSidebarShown] = useState(!isMobileOrTablet());

    useEffect(() => {
      const handler = () => {
        window.scrollPositions = window.scrollPositions || {};
        window.scrollPositions.videos = window.pageYOffset;
        window.scrollPositions.videosQuery = videos.queryString;
      }
      document.addEventListener('scroll', handler);

      return () => {
        document.removeEventListener('scroll', handler);
      }
    }, [videos]);

    useEffect(() => {
      if (!Array.isArray(videos.videos) && videos.videos.length === 0) {
        return;
      }
      if (window.scrollPositions && window.scrollPositions.videos && window.scrollPositions.videosQuery === videos.queryString) {
        window.scroll(0, window.scrollPositions.videos);
      } else {
        window.scrollPositions = window.scrollPositions || {};
        window.scroll(0, 0);
        window.scrollPositions.videos = 0;
        window.scrollPositions.videosQuery = videos.queryString;
      }
    }, [videos, queryString]);

    const history = useHistory();

    const changeQueryHandler = (newQueryString) => {
        if (newQueryString.charAt(0) !== '?') {
            newQueryString = '?' + newQueryString;
        }
        history.push(location.pathname + newQueryString);
        setQueryString(newQueryString);
    };

    const additionalSidebar = (
        <React.Fragment>
          <Divider/>
          {videos.filter ? <Filter filter={videos.filter} queryString={videos.queryString} changeQueryHandler={changeQueryHandler} /> : null}
        </React.Fragment>
    );

    const params = new URLSearchParams(queryString);
    let searchQuery = params.get('q');

    const headline = searchQuery  ? 'Suche nach ' + searchQuery : 'Videos';

    return (
        <Layout additionalNavigation={additionalSidebar} sidebarShown={sidebarShown} setSidebarShown={setSidebarShown} sidebarScrollTo={isMobileOrTablet() ? ".Filter" : undefined}>
          <div className="Videos" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '0.5em'}}>
            <div>
              <Typography variant="h1" component="h1">
                {headline}
              </Typography>
            </div>
            {sidebarShown ? null : (<FilterListIcon onClick={() => {setSidebarShown(true);}} />)}
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
              <SortIcon />
              <div style={{marginLeft: '5px'}}>
                <Select
                  value={params.get('sort') ?? 'date-desc'}
                  onChange={(e) => {
                    params.set('sort', e.target.value);
                    changeQueryHandler(params.toString());
                  }}
                >
                  <MenuItem className="selectWithIcon" value="date-desc">Jahr <ArrowDownwardIcon /></MenuItem>
                  <MenuItem className="selectWithIcon" value="date-asc">Jahr <ArrowUpwardIcon /></MenuItem>
                  <MenuItem className="selectWithIcon" value="duration-desc">Dauer <ArrowDownwardIcon /></MenuItem>
                  <MenuItem className="selectWithIcon" value="duration-asc">Dauer <ArrowUpwardIcon /></MenuItem>
                  <MenuItem className="selectWithIcon" value="recently-added">Neueste <ArrowDownwardIcon /></MenuItem>
                </Select>
              </div>
            </div>
          </div>

          <VideoList {...videos} />
        </Layout>
    );

};

export default Videos;
