export default function urlFriendlyName(input) {
  let urlFriendly = input
    .replace(/ö/g, 'oe')
    .replace(/ä/g, 'ae')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss');
  urlFriendly = input.replace(/[^a-z0-9\s]/gi, '-').replace(/\s/g, '-').toLowerCase();

  return urlFriendly;
}
