import React from 'react';
import './Grid.scss';

export function Grid({children, columns}) {
  return (
    <div
      className="Grid"
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
      }}
    >
      {children}
    </div>
  );
}
