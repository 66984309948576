import React from 'react';
import DuoIcon from '@material-ui/icons/Duo';
import './Logo.scss';
import { withStyles } from '@material-ui/core/styles';

const VideoIcon = withStyles({
    root: {
        width: 30,
        height: 30,
        marginRight: 5,
        ['@media (min-width:1000px)'] : {// eslint-disable-line no-useless-computed-key
            width: 40,
            height: 40,
        }
    }
})(DuoIcon);
const Logo = () => {
    return (<div className="Logo">
        <VideoIcon color="primary"/> FamilyTube
    </div>);
};

export default Logo;