import React from 'react';

const Duration = (props) => {
    const date = new Date(null);
    date.setSeconds(props.seconds);
    const formatted = date.toISOString().substr(11, 8).replace(new RegExp(/^00:/), '');
    
    return (<span>{formatted}</span>);
}

export default Duration;