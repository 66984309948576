import React, {useState} from 'react';
import TextInputWithConfirmCancel from "../../UI/forms/Input/TextInputWithConfirmCancel";
import {convertPlayerTimeToFormattedTimeCode} from "../../../util/timeCode";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Message from "../../UI/Message/Message";
import './MarkerList.scss';
import Typography from "@material-ui/core/Typography";
import {Edit} from "@material-ui/icons";

const MarkerList = ({makers, deleteMarkerCallback, addMarkerCallback, setSeekTo, markerError, resetMarkerError, updateMarker}) => {
  const [editingMarker, setEditingMarker] = useState(null);

  return (
    <div className="MarkerList">
      <Typography variant="h3" component="h3" gutterBottom>Kapitelmarken</Typography>
      {makers.map((marker) => {
        if (marker === editingMarker) {
          return (
            <div className="MarkerList__marker MarkerList__marker--editing" key={marker.time}>
              <TextInputWithConfirmCancel onConfirm={(name) => {if (updateMarker(marker, name)) {setEditingMarker(null);return true;} else {return false;}}} confirmLabel="Übernehmen" label="Kapitelmarke ändern" onCancel={() => {resetMarkerError(); setEditingMarker(null);}} edit initialValue={marker.name}/>
            </div>
          );
        }
        return (
          <div className="MarkerList__marker" key={marker.time} onClick={() => {setSeekTo(marker.time)}}>
            <div className="MarkerList__marker__label">{marker.name}<span>{convertPlayerTimeToFormattedTimeCode(marker.time)}</span></div>
            <div className="MarkerList__marker__icons">
              <IconButton title="Marke löschen" size="small" onClick={(e) => {e.stopPropagation();deleteMarkerCallback(marker);}}><DeleteIcon /></IconButton>
              <IconButton title="Marke editieren" size="small" onClick={(e) => {e.stopPropagation();setEditingMarker(marker); setSeekTo(marker.time);}}><Edit /></IconButton>
            </div>
          </div>
        );
      })}
      <TextInputWithConfirmCancel onConfirm={(name) => {return addMarkerCallback(name);}} confirmLabel="Kapitel hinzufügen" label="Neue Kapitelmarke" onCancel={resetMarkerError} />
      <p style={{fontSize: '12px'}}>Neue Marken werden an der aktuellen Position im Vorschauvideo eingefügt.</p>
      {markerError ? (
        <Message type="error">{markerError}</Message>
      ) : null}
    </div>
  );
}

export default MarkerList;
