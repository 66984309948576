import {updateObject} from '../utility';
import {setToken} from '../../ajax/ajax';

const defaultState = {
    isLoggingIn: false,
    error: null,
    token: null,
    userName: null,
    roles: [],
    isAuthenticated: false,
    authenticationStateUnclear: true
};

const authReducer = (previousState = defaultState, action) => {
    switch(action.type) {
        case 'AUTH_START':
            return updateObject(previousState, {isLoggingIn: true, authenticationStateUnclear: false});
        case 'AUTH_ERROR':
            return updateObject(previousState, {isLoggingIn: false, error: action.error, authenticationStateUnclear: false});
        case 'AUTH_SUCCESS':
            setToken(action.token);
            return updateObject(previousState, {isLoggingIn: false, error: null, isAuthenticated: true, authenticationStateUnclear: false, token: action.token, userName: action.userName, roles: action.roles});
        case 'AUTH_CLEAR':
            localStorage.removeItem('auth.token');
            setToken(null);
            return {...defaultState, authenticationStateUnclear: false};
        default:
            break;
    }

    return previousState;
};

export default authReducer;
