import React, {useEffect} from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import {getToken} from "../../ajax/ajax";
import PhotoSwipeFullscreen from './photoswipe-fullscreen';
import PhotoSwipeSlideShow from './photoswipe-slideshow';
import PhotoSwipeShowHideControls from './photoswipe-show-hide-controls';
import 'photoswipe/style.css';
import './GalleryImages.scss';
import {v4} from "uuid";
import PhotoSwipeCaptions from "./photoswipe-captions";
import isTouchDevice from "../../util/isTouchDevice";

const GalleryImages = ({images}) => {
  const containerId = 'c'+v4();
  useEffect(() => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '#'+containerId,
      children: 'a',
      thumbSelector: 'img',
      pswpModule: () => import('photoswipe')
    });
    new PhotoSwipeFullscreen(lightbox, {
      fullscreenTitle: 'Vollbild'
    });
    new PhotoSwipeSlideShow(lightbox, {});
    if (!isTouchDevice()) {
      new PhotoSwipeShowHideControls(lightbox, {});
    }
    new PhotoSwipeCaptions(lightbox, {});
    lightbox.init();

    return () => {
      lightbox.destroy();
    }

  }, [images]);

  return (
    <div className="GalleryImages" id={containerId}>
        {images.map((image) => {
          return (
            <div key={image.id} className="GalleryImages__image">
              <a className="GalleryImages__thumb" data-pswp-src={image.large + '?token='+encodeURIComponent(getToken())} data-pswp-width={image.width} data-pswp-height={image.height}>
                <img src={image.small + '?token='+encodeURIComponent(getToken())} alt={image.text} />
              </a>
            </div>
            );
        })}
    </div>
  );
}

export default GalleryImages;
