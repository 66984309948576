import React from 'react';
import {withRouter} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import InfoWithIcon from "../../InfoWithIcon/InfoWithIcon";
import YearIcon from "@material-ui/icons/CalendarToday";
import PinIcon from "@material-ui/icons/Room";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import PersonAvatar from '../../PersonAvatar/PersonAvatar';
import Chip from '@material-ui/core/Chip';
import {Button} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {ajaxConfig} from "../../../ajax/ajax";
import Fab from "@material-ui/core/Fab";
import FavoriteButton from "../../FavoriteButton/FavoriteButton";
import useFavorites from "../../../hooks/useFavorites";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4),
    },
    people: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        marginTop: theme.spacing(3),
        '& > *': {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    infoIcons: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        marginTop: theme.spacing(2),
        fontSize: '1.4rem',
        '& > *': {
            marginRight: theme.spacing(2),
        },
    },
    infoIcon:  { fontSize: '1.6rem',  verticalAlign: 'top', marginRight: theme.spacing(0.5)}
}));

const VideoDetails = (props) => {
    const classes = useStyles();
    const [favorites, addFavorite, removeFromFavorites] = useFavorites();

    let places = null;
    if (props.video.places && props.video.places.length > 0 ) {
        places = (<InfoWithIcon icon={(<PinIcon className={classes.infoIcon}/>)}>{props.video.places.join(', ')}</InfoWithIcon>);
    }

    let people = null;
    if (props.video.people && props.video.people.length > 0) {
        people = (
            <div className={classes.people}>
                {props.video.people.map((person) => {return (
                    <Chip key={person} avatar={ <PersonAvatar personName={person}/>} label={person} size="medium"/>
                );})}
            </div>
        );
    }


    const isFavorite = null !== favorites && favorites.indexOf(props.video.id) > -1;
    const favoriteButton = (
      <Fab size="small" title={isFavorite ? "Von Favoriten entfernen" : "Zu Favoriten hinzufügen"} onClick={() => {addFavorite(props.video.id)}} component='div' style={{marginLeft: '20px', position:'relative', top: '-2px'}}>
          <FavoriteButton addToFavorites={() => {addFavorite(props.video.id)}} removeFromFavorites={() => {removeFromFavorites(props.video.id)}} isFavorite={isFavorite} />
      </Fab>
    );

    return (
        <div className={classes.root}>
            <Typography variant="h1" component="h1" gutterBottom>
                { props.video.title }
            </Typography>
            <div className={classes.infoIcons}>
                <InfoWithIcon icon={(<YearIcon className={classes.infoIcon}/>)}>{props.video.year.join('/')}</InfoWithIcon>
                {places}
            </div>
            {people}
            {props.video.description && props.video.description !== '' ? <p>{ props.video.description }</p> : null}

            <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                <Button href={ajaxConfig.baseUrl + '/api/v1/download/' + props.video.id + '?token=' + ajaxConfig.token} variant="contained" startIcon={<DownloadIcon />} size={"small"}>Download</Button>
                {favoriteButton}
            </div>
        </div>
    );
};

export default withRouter(VideoDetails);
