import axios from "axios";
import {getAjaxConfig} from "../ajax/ajax";

export async function uploadFile(file, chunkSizeMb, setProgress, existingToken) {
  const ajaxConfig = getAjaxConfig();
  const chunkSize = 1048576 * chunkSizeMb;
  let beginningOfTheChunk = 0;
  let endOfTheChunk = chunkSize;
  const chunkCount = file.size % chunkSize === 0 ? file.size / chunkSize : Math.floor(file.size / chunkSize) + 1;

  const response = await axios.post(
    `${ajaxConfig.baseUrl}/api/v1/upload/start`,
    {
    },
    {
      params: {
        token: existingToken ?? '',
      },
      headers: { "Content-Type": "application/json", "x-auth-token": ajaxConfig.token },
    }
  );
  const token = response.data.token ?? null;
  if (!token) {
    return false;
  }

  const fileKey = response.data.fileKey ?? null;
  if (!fileKey) {
    return false;
  }

  const uploadChunk = async (chunk) => {
    try {
      const response = await axios.post(
        `${ajaxConfig.baseUrl}/api/v1/upload/chunk`,
        chunk,
        {
          params: {
            number: counter,
            fileKey,
            token,
          },
          headers: { "Content-Type": "application/octet-stream", "x-auth-token": ajaxConfig.token },
        }
      );
      const data = response.data;
      if (data.success) {
        beginningOfTheChunk = endOfTheChunk;
        endOfTheChunk = endOfTheChunk + chunkSize;
        if (counter === chunkCount) {
          return await uploadCompleted();
        } else {
          const percentage = (counter / chunkCount) * 100;
          setProgress(percentage, false);
          return true;
        }
      } else {
        throw Error(data.message);
      }
    } catch (error) {
      throw Error(error.response.data.message);
    }
  };

  const uploadCompleted = async () => {
    const response = await axios.post(
      `${ajaxConfig.baseUrl}/api/v1/upload/finish`,
      {},
      {
        headers: {
          "x-auth-token": ajaxConfig.token
        },
        params: {
          token,
          fileKey,
          actualName: file.name,
        },
      }
    );
    const data = response.data;
    if (data.success) {
      setProgress(100, true, data.id);
      return true;
    }

    return false;
  };

  let counter = 1;
  while (counter <= chunkCount) {
    const chunk = file.slice(beginningOfTheChunk, endOfTheChunk);
    const success = await uploadChunk(chunk);
    if (!success) {
      return false;
    }
    counter++;
  }

  return token;
}
