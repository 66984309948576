import React from 'react';

import './VideoMarkers.scss';
import Typography from "@material-ui/core/Typography";
import {convertPlayerTimeToFormattedTimeCode} from "../../../util/timeCode";

const VideoMarkers = (props) => {

    if (!props.markers || props.markers.length === 0) {
        return null;
    }

    return (
        <div className="VideoMarkers">
            <Typography variant="h3" component="h2" gutterBottom>Kapitel/Highlights</Typography>
            <ul className="markers">
                {props.markers.map((marker) => {
                    return (
                        <li key={marker.name+marker.time.toString()}
                            onClick={(e) => {e.preventDefault();props.onMarkerClick(marker.time);}}
                        >{ marker.name }<span>{convertPlayerTimeToFormattedTimeCode(marker.time)}</span>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};

export default VideoMarkers;
