import React, {useState, useEffect} from 'react';
import Layout from '../Layout/Layout';
import VideoMarkers from '../../components/Video/UpdatedPlayer/VideoMarkers';
import VideoDetails from '../../components/Video/VideoDetails/VideoDetails';
import Spinner from '../../components/UI/Spinner/Spinner';
import Message from "../../components/UI/Message/Message";
import {useParams} from 'react-router-dom';
import ajax from '../../ajax/ajax';
import './VideoDetailPage.scss';
import MoreVideosList from "../../components/VideoList/MoreVideosList";
import useVideos from '../../hooks/useVideos';
import Typography from "@material-ui/core/Typography";
import UpdatedPlayer from "../../components/Video/UpdatedPlayer/UpdatedPlayer";

const VideoDetailPage = (props) => {

    const [video, setVideo] = useState(null);
    const [error, setError] = useState(null);
    const [seekTo, setSeekTo] = useState(null);

    const {videoString} = useParams();
    const videoId = videoString.split('_').pop();

    useEffect(() => {
        const {cancel} = ajax.get('/api/v1/videos/'+encodeURIComponent(videoId), (response) => {
            setVideo(response.video);
        }, (error) => {
            setError(error);
        });

        return () => {
            cancel();
        }
    }, [videoId]);

    const [moreVideos] = useVideos('?moreLikeThis[]='+encodeURIComponent(videoId)+'&size=5');

    if (null !== error) {
        return (<Layout>{<Message type="error">{error.toString()}</Message>}</Layout>);
    }

    let content = (<Spinner/>);
    if (null !== video) {
        content = (
            <div className="VideoDetailPage">
                <div className="Video">
                    <UpdatedPlayer url={video.streamUrl} autoPlay={true} onPlayHeadTimeChanged={(time) => {}} markers={Array.isArray(video.markers) ? video.markers : []} seekTo={seekTo} showControlsOnlyOnHover={true} showOverlayAfterPlaybackEnded={true} videoId={video.id}/>
                    <VideoDetails video={video} />
                </div>
                <div className="VideoSideBar">
                    {video.markers.length > 0 ? <VideoMarkers markers={video.markers} onMarkerClick={setSeekTo}/> : null}
                    {moreVideos && moreVideos.videos.length > 0 ? (<React.Fragment><Typography variant="h3" component="h2" gutterBottom>Weitere Videos</Typography><MoreVideosList {...moreVideos} /></React.Fragment>) : null}
                </div>
            </div>
        );
    }

    return (
        <Layout closeMenuAsDefault={true}>
            {content}
        </Layout>
    );
};

export default VideoDetailPage;
