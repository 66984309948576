import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import VideosIcon from '@material-ui/icons/Videocam';
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import AdminIcon from "@material-ui/icons/SettingsOutlined";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MovieCreationIcon from '@material-ui/icons/MovieCreation';
import ImageIcon from '@material-ui/icons/Image';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

import './NavigationBar.scss';
import {connect} from "react-redux";
import Divider from "@material-ui/core/Divider";

function ListItemLink(props) {
    let match = useRouteMatch({
        path: props.to,
        exact: props.exact
    });
    const LinkComponent = React.forwardRef((props, ref) => {
        const newProps = {...props};
        delete newProps.exact;
        return (<Link innerRef={ref} {...newProps} />);
    });
    return <ListItem button component={LinkComponent} {...props} selected={match !== null} />;
}

const NavigationBar = (props) => {
    return (
        <React.Fragment>
            <List component="nav" aria-label="Hauptnavigation">
                <ListItemLink match='/' exact to='/'>
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItemLink>
                <ListItemLink match='/videos' to='/videos'>
                    <ListItemIcon>
                        <VideosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Videos" />
                </ListItemLink>
                <ListItemLink match='/galleries' to='/galleries'>
                    <ListItemIcon>
                        <ImageIcon />
                    </ListItemIcon>
                    <ListItemText primary="Bilder" />
                </ListItemLink>
                <ListItemLink match='/favoriten' to='/favoriten' exact>
                    <ListItemIcon>
                        <FavoriteBorder />
                    </ListItemIcon>
                    <ListItemText primary="Favoriten" />
                </ListItemLink>
                <Divider />
                <ListItemLink match='/uploads' to='/uploads' exact>
                    <ListItemIcon>
                        <MovieCreationIcon />
                    </ListItemIcon>
                    <ListItemText primary="Meine Videos" />
                </ListItemLink>
                <ListItemLink match='/upload' to='/upload' exact>
                    <ListItemIcon>
                        <CloudUploadIcon />
                    </ListItemIcon>
                    <ListItemText primary="Video Upload" />
                </ListItemLink>
                <ListItemLink match='/uploadsGalleries' to='/uploadsGalleries' exact>
                    <ListItemIcon>
                        <PhotoLibraryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Meine Galerien" />
                </ListItemLink>
                <ListItemLink match='/upload' to='/uploadGallery' exact>
                    <ListItemIcon>
                        <CloudUploadIcon />
                    </ListItemIcon>
                    <ListItemText primary="Bilder Upload" />
                </ListItemLink>
                {props.roles.includes('ROLE_ADMIN') ? (
                  <ListItemLink match='/admin' to='/admin' exact>
                      <ListItemIcon>
                          <AdminIcon />
                      </ListItemIcon>
                      <ListItemText primary="Admin" />
                  </ListItemLink>
                ) : null}
                {props.additionalNavigation ? props.additionalNavigation : null}
            </List>

        </React.Fragment>
    );
};

const stateToProps = (state) => {
    return {
        roles: state.auth.roles
    };
};


export default connect(stateToProps)(NavigationBar);
