import React from 'react';

import './FormActions.scss';

const FormActions = (props) => {
    return (
        <div className="FormActions">
            {props.children}
        </div>
    );
};

export default FormActions;