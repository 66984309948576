const detectionPixel = document.querySelector('#detectionPixel');
export const mobile = 'mobile';
export const tablet = 'tablet';
export const desktop = 'desktop';

export function getCurrentBreakpoint() {
  if (!detectionPixel) {
    return mobile;
  }

  switch (getComputedStyle(detectionPixel).width) {
    case '1px':
      return tablet;
    case '2px':
      return desktop;
    default:
      return mobile;
  }
}

export function isMobile() {
  return getCurrentBreakpoint() === mobile;
}

export function isMobileOrTablet() {
  return [mobile, tablet].includes(getCurrentBreakpoint());
}

let currentBreakpoint = getCurrentBreakpoint();

const resizedCallback = () => {
  const newBreakpoint = getCurrentBreakpoint();
  if (newBreakpoint !== currentBreakpoint) {
    window.dispatchEvent(new CustomEvent('breakpoint.changed', {
      detail: {
        previous: currentBreakpoint,
        current: newBreakpoint
      }
    }));
    currentBreakpoint = newBreakpoint;
  }
}

export function initBreakpointChangeEvents() {
  window.addEventListener('resize', resizedCallback);
  window.addEventListener('orientationchange', resizedCallback);
}
