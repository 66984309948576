import {useEffect, useState} from 'react';
import ajax from '../ajax/ajax';

const useGalleries = (initialQueryString) => {
    const [queryString, setQueryString] = useState(initialQueryString);
    const [apiResponse, setApiResponse] = useState({galleries: [], filter: []});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (queryString === null) {
            return;
        }

        setLoading(true);
        const {cancel} = ajax.get('/api/v1/galleries' + queryString, (response) => {
            setApiResponse(response);
            setLoading(false);
        }, (error, status) => {
            let errorMessage = 'Server nicht erreichbar.';
            if (error) {
                errorMessage = error;
            }

            if (status === 401) {
                errorMessage = 'Login erforderlich.';
            }

            setError(errorMessage);
            setLoading(false);
        });

        return () => {
            cancel();
        }
    }, [queryString]);

    return [loading, error, apiResponse, setQueryString, queryString];
};

export default useGalleries;
