import React from "react";
import {makeStyles} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useFavorites from "../../../hooks/useFavorites";
import {useHistory} from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import FavoriteButton from "../../FavoriteButton/FavoriteButton";
import ReplayIcon from '@material-ui/icons/Replay';
import {isMobile} from "../../../util/breakpoints";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    favButton: {
        marginLeft: theme.spacing(2),
    },
}));

const PlaybackEnded = ({videoId, show, play}) => {
    const mobile = isMobile();
    const classes = useStyles();

    const history = useHistory();

    const handleBackButton = () => {
        history.goBack();
    };

    const [favorites, addFavorite, removeFromFavorites] = useFavorites();
    const isFavorite = null !== favorites && favorites.indexOf(videoId) > -1;
    const favoriteButton = (
        <Fab aria-label="like" onClick={() => {addFavorite(videoId)}} className={classes.favButton} component='div'>
            <FavoriteButton addToFavorites={() => {addFavorite(videoId)}} removeFromFavorites={() => {removeFromFavorites(videoId)}} isFavorite={isFavorite} />
        </Fab>
    );

    if (!show) {
        return null;
    }

    return (
        <div className={classes.root}>
            <Fab variant="extended" onClick={handleBackButton}>
                <ArrowBackIcon className={classes.extendedIcon} />
                Zurück
            </Fab>
            <Fab className={classes.favButton} variant="extended" onClick={play}>
                <ReplayIcon className={classes.extendedIcon} />
                Nochmal spielen
            </Fab>
            {mobile ? null : favoriteButton}
        </div>
    );
};

export default PlaybackEnded;
