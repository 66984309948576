import React, {lazy, Suspense} from 'react';
import {createStore, combineReducers, compose, applyMiddleware } from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import authReducer from './store/reducers/authReducer';
import WithAuth from './hoc/withAuth/withAuth';
import Home from './containers/Home/Home';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Videos from './containers/Videos/Videos';
import Favorites from './containers/Favorites/Favorites';
import Spinner from './components/UI/Spinner/Spinner';
import VideoDetailPage from "./containers/VideoDetailPage/VideoDetailPage";
import Uploads from "./containers/Upload/Uploads";
import EditVideo from "./containers/Upload/EditVideo";
import Upload from "./containers/Upload/Upload";
import './App.scss';
import ImageUpload from "./containers/ImageUpload/ImageUpload";
import Galleries from "./containers/Galleries/Galleries";
import GalleryDetailPage from "./containers/GalleryDetailPage/GalleryDetailPage";
import MyGalleries from "./containers/ImageUpload/MyGalleries";
import EditGallery from "./containers/ImageUpload/EditGallery";
import ScrollToTop from "./hoc/ScrollToTop";
import Search from "./containers/Search/Search";

const rootReducer = combineReducers(
  {
      'auth': authReducer,
  }
);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));

const Admin = (
  lazy(() => (
    import('./containers/Admin/Admin')
  ))
);

function App() {

  return (
    <Provider store={store}>
      <div className="App">
          <Router>
            <ScrollToTop>
              <WithAuth>
                  <Suspense fallback={<Spinner />}>
                    <Switch>
                      <Route path='/search' component={Search}/>
                      <Route path='/videos' component={Videos}/>
                      <Route path='/galleries' component={Galleries}/>
                      <Route path='/favoriten' component={Favorites}/>
                      <Route path='/video/:videoString' component={VideoDetailPage}/>
                      <Route path='/gallery/:galleryString' component={GalleryDetailPage}/>
                      <Route path='/uploadsGalleries' component={MyGalleries} />
                      <Route path='/uploads' component={Uploads} />
                      <Route path='/upload/edit/:videoId' component={EditVideo} />
                      <Route path='/uploadGallery/edit/:galleryId' component={EditGallery} />
                      <Route path='/uploadGallery' component={ImageUpload} />
                      <Route path='/upload' component={Upload} />
                      <Route path='/admin' component={Admin} />
                      <Route path='/' component={Home} /> {/*Needs to be last -> catch all*/}
                    </Switch>
                  </Suspense>
              </WithAuth>
            </ScrollToTop>
         </Router>
      </div>
    </Provider>
  );
}

export default App;
