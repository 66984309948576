import React, {useState} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import FormActions from '../../components/UI/forms/FormActions/FormActions';
import {connect} from 'react-redux';
import {login as authLoginActionCreator} from '../../store/actions/authActions';
import Message from '../../components/UI/Message/Message';
import StandaloneHeader from '../../components/Header/StandaloneHeader';
import Logo from '../../components/Logo/Logo';
import Layout from '../Layout/Layout';
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import emailValidator from 'email-validator';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';

import './Login.scss';
import ajax from "../../ajax/ajax";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
        marginBottom: theme.spacing(4),
    },
    form: {
        width: '100%',
        margin: 'auto',
        marginTop: theme.spacing(4),
        ['@media (min-width:1000px)'] : {// eslint-disable-line no-useless-computed-key
            width: 350,
        }
    },
    bottomMargin: {
        marginBottom: theme.spacing(4),
    },
    errorSnackbar: {
        backgroundColor: theme.palette.error.dark,
        marginBottom: theme.spacing(4),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    messageIcon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
}));

const Login = (props) => {
    const [isRegister, setIsRegister] = useState(false);
    const [registerError, setRegisterError] = useState(null);
    const [emailField, setEmailField] = useState({
        value: '',
        valid: true,
        validationMessage: '',
    });

    const [passwordField, setPasswordField] = useState({
        value: ''
    });

    const [password2Field, setPassword2Field] = useState({
        value: ''
    });

    const [codeField, setCodeField] = useState({
        value: ''
    });

    const loginHandler = () => {
        if (isRegister) {
            ajax.post('/api/register', {email: emailField.value, password: passwordField.value, password2: password2Field.value, code: codeField.value}, () => {
                props.onLogin(emailField.value, passwordField.value);
            }, (xhrStatusText, xhrStatus, xhr) => {
                setRegisterError(JSON.parse(xhr.responseText).error);
            });
            return;
        }
        props.onLogin(emailField.value, passwordField.value);
    };

    const loginOnEnter = (ev) => {
        if (ev.key === 'Enter') {
            loginHandler();
            ev.preventDefault();
        }
    };

    const submitHandler = (e) => {
        e.preventDefault();
        loginHandler();
    };

    const classes = useStyles();

    const handleEmailChange = (e) => {
        setEmailField({
            value: e.target.value,
            valid: true,
            validationMessage: '',
        });
    };

    const handlePasswordChange = (e) => {
        setPasswordField({
            value: e.target.value
        });
    };

    const handlePassword2Change = (e) => {
        setPassword2Field({
            value: e.target.value
        });
    };

    const handleCodeChange = (e) => {
        setCodeField({
            value: e.target.value
        });
    };

    const validateEmail = (e) => {
        setEmailField((previousState) => {
            const isValid = emailValidator.validate(previousState.value);
            return {...previousState, valid: isValid, validationMessage: !isValid ? 'Keine gültige E-Mail-Adresse' : ''}
        });
    };

    const handleClickShowPassword = () => {
        setPasswordField((previousState) => {
            return {...previousState, showPassword: !previousState.showPassword};
        });
    };

    const handleClickShowPassword2 = () => {
        setPassword2Field((previousState) => {
            return {...previousState, showPassword: !previousState.showPassword};
        });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    let loginForm = (
        <div className={classes.form}>
            {null !== props.error ? <Message type="error">{props.error}</Message> : null}
            {null !== registerError ? <Message type="error">{registerError}</Message> : null}
            <form onSubmit={submitHandler} method="post">


                <TextField
                    className={classes.bottomMargin}
                    fullWidth
                    required
                    error={!emailField.valid}
                    helperText={!emailField.valid ? emailField.validationMessage : ''}
                    id="email"
                    label="E-Mail"
                    variant="outlined"
                    color="secondary"
                    value={emailField.value}
                    onChange={handleEmailChange}
                    onBlur={validateEmail}
                    onKeyPress={loginOnEnter}
                />

                <TextField
                    className={isRegister ? classes.bottomMargin : ''}
                    fullWidth
                    required
                    id="password"
                    label={isRegister ? "Neues Passwort" : "Passwort"}
                    type={passwordField.showPassword ? 'text' : 'password'}
                    variant="outlined"
                    color="secondary"
                    value={passwordField.value}
                    onChange={handlePasswordChange}
                    onKeyPress={loginOnEnter}
                    InputProps={{
                        endAdornment:
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                        {passwordField.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        </InputAdornment>
                        ,
                    }}
                />

                {isRegister ? (
                  <React.Fragment>
                      <TextField
                        className={classes.bottomMargin}
                        fullWidth
                        required
                        id="password2"
                        label="Passwort Wiederholung"
                        type={password2Field.showPassword ? 'text' : 'password'}
                        variant="outlined"
                        color="secondary"
                        value={password2Field.value}
                        onChange={handlePassword2Change}
                        InputProps={{
                            endAdornment:
                              <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword2}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                      {password2Field.showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                              </InputAdornment>
                            ,
                        }}
                      />

                      <TextField
                        fullWidth
                        required
                        id="code"
                        label="Einladungs-Code"
                        variant="outlined"
                        color="secondary"
                        value={codeField.value}
                        onChange={handleCodeChange}
                      />
                  </React.Fragment>
                ) : null}


                <FormActions>
                    <Button variant="contained" color="primary" size="large" onClick={loginHandler} disabled={props.isLoggingIn}>Login</Button>
                </FormActions>
                <br />
                Ich habe einen Registrierungs-Code:
                <Switch
                  checked={isRegister}
                  onChange={() => {setIsRegister(!isRegister)}}
                />

            </form>
        </div>
    );
    if (props.isAuthenticated) {
        loginForm = (
            <React.Fragment>
                <Message type="success">Eingeloggt.</Message>
                <Button onClick={props.onLogout} variant="contained">Logout</Button>
            </React.Fragment>
        );
    }

    return (
        <Layout isStandalone>
            <StandaloneHeader><Logo /></StandaloneHeader>
            <div className="Login">
                <Container maxWidth="sm">
                    <Paper className={classes.root}>
                        <Typography variant="h1" component="h1" gutterBottom>
                            Login
                        </Typography>
                        {loginForm}
                    </Paper>
                    {props.isLoggingIn ? <div><LinearProgress /></div> : null}
                </Container>
            </div>
        </Layout>
    )
};

const stateToProps = (state) => {
    return {
        isLoggingIn: state.auth.isLoggingIn,
        isAuthenticated: state.auth.isAuthenticated,
        error: state.auth.error,
    };
};

const actionsToProps = (dispatch) => {
    return {
        onLogin: (email, password) => dispatch(authLoginActionCreator(email, password)),
        onLogout: () => {
            dispatch({
                type: 'AUTH_CLEAR'
            });
        }
    };
};

export default connect(stateToProps, actionsToProps)(Login);
