import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import ajax, {getToken} from "../../ajax/ajax";
import Layout from "../Layout/Layout";
import Message from "../../components/UI/Message/Message";
import Spinner from "../../components/UI/Spinner/Spinner";
import VideoCard from "../../components/Upload/VideoCard";
import './Uploads.scss';
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

const Uploads = () => {
  const [videos, setVideos] = useState(null);
  const [error, setError] = useState(null);
  const [videoToDelete, setVideoToDelete] = useState(null);

  useEffect(() => {
    const cancelRequest = ajax.get('/api/v1/upload/videos', (data) => {
      setVideos(data.videos);
    }, (err) => {
      setError(err);
    }).cancel;

    return () => {
      if (cancelRequest) {
        cancelRequest();
      }
    };
  }, []);

  useEffect(() => {
    if (null === videoToDelete) {
      return;
    }
    const cancelRequest = ajax.delete(`/api/v1/upload/video/${videoToDelete}`, (data) => {
      setVideos((prevState) => {
        const newState = [...prevState];
        return newState.filter((candidate) => {
          return videoToDelete !== candidate.id;
        });
      });
      setVideoToDelete(null);
    }, (err) => {
      setError('Video konnte nicht gelöscht werden.');
    }).cancel;

    return () => {
      if (cancelRequest) {
        cancelRequest();
      }
    };
  }, [videoToDelete]);

  function deleteVideo(id) {
    setVideoToDelete(id);
  }

  let content = null;
  if (null !== error) {
    content = (<Message type="error">{error.toString()}</Message>);
  } else if (!videos) {
    content = (<Spinner />);
  } else if (videos.length === 0) {
    content = (
      <React.Fragment>
        <Typography variant="h1" component="h1" gutterBottom>Deine Videos</Typography>
        <p style={{fontSize: '20px'}}>Du hast noch keine Videos hochgeladen!</p>
        <Button variant="contained" color="primary" component="a" href="/upload">Lade dein erstes Video hoch</Button>
      </React.Fragment>
    );
  } else {
    const queuedVideos = videos.filter((candidate) => {
      return candidate.published === 0;
    });
    const finishedVideos = videos.filter((candidate) => {
      return candidate.published === 1;
    });
    const hasDivider = queuedVideos.length > 0 &&  finishedVideos.length > 0;
    content = (
      <div className="Uploads">
        <Typography variant="h1" component="h1" gutterBottom>Deine Videos</Typography>
        <p style={{marginBottom: '25px'}}>Hier siehst du eine Übersicht deiner Videos. Du kannst den Verarbeitungsstatus prüfen oder Änderungen vornehmen.</p>
        <Button variant="contained" color="secondary" component="a" href="/upload" style={{marginBottom: '40px'}}>Neues Video hochladen</Button>
        {queuedVideos.length > 0 ? (
          <React.Fragment>
            {hasDivider ? (<Typography variant="h2" component="h2" gutterBottom>In Bearbeitung</Typography>) : null}
            <div className="Uploads__videos">
              {queuedVideos.map((video) => {
                  return (<VideoCard key={video.id} {...video} thumbnailUrl={null} onDeleteVideo={deleteVideo} />);
                })}
            </div>
          </React.Fragment>
        ) : null}
        {hasDivider ? (<div className="Uploads__divider"><Divider /></div>) : null}
        {finishedVideos.length > 0 ? (
          <React.Fragment>
            {hasDivider ? (<Typography variant="h2" component="h2" gutterBottom>Veröffentlicht</Typography>) : null}
            <div className="Uploads__videos">
              {finishedVideos.map((video) => {
                return (<VideoCard key={video.id} {...video} thumbnailUrl={video.thumbnailUrl + '&token='+encodeURIComponent(getToken())} onDeleteVideo={deleteVideo} />);
              })}
            </div>
          </React.Fragment>
        ) : null}
      </div>
    );
  }

  return (
    <Layout>
      {content}
    </Layout>
  );
}

export default Uploads;
