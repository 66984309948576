import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import Login from '../../containers/Login/Login';
import {check as checkAuthActionCreator} from '../../store/actions/authActions';

const WithAuth = (props) => {

    const authCheck = props.onCheckAuth;
    useEffect(
        () => {
          authCheck();
        },
        [authCheck]
      );

    let content = null;
    if (props.authenticationStateUnclear) {
        return null;
    }
    if (props.needsLogin) {
        content = (<Login />);
    } else {
        content =(<React.Fragment>{props.children}</React.Fragment>)
    }

    return content;
}

const stateToProps = (state) => {
    return {
        needsLogin: !state.auth.isAuthenticated,
        authenticationStateUnclear: state.auth.authenticationStateUnclear
    }
}

const actionsToProps = (dispatch) => {
    return {
        onCheckAuth: () => dispatch(checkAuthActionCreator())
    }
}

export default connect(stateToProps, actionsToProps)(WithAuth);