import React from 'react';

const InfoWithIcon = (props) => {
  return(
      <div className="InfoWithIcon">
          {props.icon}
          {props.children}
      </div>
  );
};

export default InfoWithIcon;