import React, {useEffect, useRef, useState} from "react";

const VideoPreview = ({file}) => {
  const [poster, setPoster] = useState("/static/images/movie.png");
  const [showControls, setShowControls] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    video.src = URL.createObjectURL(file);

    function canPlayHandler(e) {
      setPoster(null);
      video.pause();
      video.currentTime = 0.5; // attention: this triggers canplay event
    }
    video.addEventListener('loadeddata', canPlayHandler);
    return () => {
      video.removeEventListener('loadeddata', canPlayHandler);
      URL.revokeObjectURL(video.src);
    }
  },[file]);

  return (
    <video width="150px" style={{marginRight: '20px'}} ref={videoRef} controls={showControls} poster={poster}  onClick={() => {setShowControls(true)}} webkit-playsinline playsinline></video>
  );
}

export default VideoPreview;
