import React, {useEffect, useRef, useState} from 'react';
import Header from '../../components/Header/Header';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import {isMobileOrTablet} from "../../util/breakpoints";
import './Layout.scss';

const theme = createTheme({
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '0.5em',
            },
        },
    },
    palette: {
        primary: { main: '#E62243' },
        secondary: { main: '#177c84' },
    },
    typography: {
        h1: {
            fontSize: '2.5rem',
        },
        h2: {
            fontSize: '2.2rem',
        },
        h3: {
            fontSize: '1.4rem',
            fontWeight: 300,
            letterSpacing: '-0.01562em',
        },
    }
});

const Layout = ({isStandalone, children, additionalNavigation, closeMenuAsDefault, sidebarShown, setSidebarShown, sidebarScrollTo}) => {
    const [mobileSearchBarIsShown, setMobileSearchBarIsShown] = useState(false);
    const [menuShown, setMenuShown] = useState(!(isMobileOrTablet() || closeMenuAsDefault)); // This is used when sidebarShown is undefined, meaning it's not handled outside

    const contentItem = useRef(null);
    const sidebarScrollContainer = useRef(null);
    useEffect(() => {
        function onDocumentClick(e) {
            if (menuShown && isMobileOrTablet()) {
                setMenuShown(false);
            }
            if (sidebarShown !== undefined && isMobileOrTablet()) {
                setSidebarShown(false);
            }
        }

        if (!contentItem.current) {
            return;
        }
        contentItem.current.addEventListener('click', onDocumentClick);

        return () => {
            contentItem.current.removeEventListener('click', onDocumentClick);
        }
    }, [menuShown, sidebarShown]);

    useEffect(() => {
        if (!sidebarScrollContainer.current || !sidebarScrollTo) {
            return;
        }
        const scrollTo = sidebarScrollContainer.current.querySelector(sidebarScrollTo);
        if (scrollTo) {
            sidebarScrollContainer.current.scrollTop = scrollTo.offsetTop - 10;
        }

    }, [sidebarShown]);

    const handleSearchToggle = () => {
        setMobileSearchBarIsShown(!mobileSearchBarIsShown);
    };

    function toggleMenu() {
        setMenuShown(!menuShown);
        if (sidebarShown !== undefined) {
            setSidebarShown(!sidebarShown);
        }
    }

    let content;
    if (isStandalone) {
        content = children
    } else {
        const sidebar = (
            <div className="Layout__sidebar">
                <div className="Layout__sidebar__inner" ref={sidebarScrollContainer}>
                    <div className="Layout__sidebar__content">
                        <NavigationBar additionalNavigation={additionalNavigation} />
                    </div>
                </div>
            </div>
        );

        const classes = ['Layout'];
        if ((sidebarShown === undefined && menuShown) || (sidebarShown !== undefined && sidebarShown)) {
            classes.push('Layout--openSidebar');
        }
        if (mobileSearchBarIsShown) {
            classes.push('Layout--mobileSearchBar');
        }

        content = (
            <div className={classes.join(' ')}>
                <div className="Layout__header"><Header searchToggleHandler={handleSearchToggle} toggleMenu={toggleMenu} menuOpen={menuShown} /></div>
                {sidebar}
                <div className="Layout__content" ref={contentItem}>
                    <div className="Layout__content__inner">
                        {children}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {content}
        </ThemeProvider>
    );
};

export default Layout;
