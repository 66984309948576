import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import {uploadFile} from "../../upload/chunkedUploads";
import LinearProgress from "@material-ui/core/LinearProgress";
import {ListItem, ListItemAvatar, ListItemSecondaryAction} from "@material-ui/core";
import isNumeric from '../../util/isNumeric';
import ajax from "../../ajax/ajax";
import Message from "../UI/Message/Message";
import Explainer from "../UI/forms/Guide/Explainer";
import Info from "../UI/forms/Guide/Info";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import List from "@material-ui/core/List";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DoneIcon from '@material-ui/icons/Done';
import VideoPreview from "./VideoPreview";
import './VideoUploader.scss';
import './VideoForm.scss';
import VideoOrGalleryDetails from "./VideoOrGalleryDetails";

const defaultSteps = {
  selectFiles: {done: false, readyForUpload: false},
  title: {done: false},
  uploadFiles: {done: false, inProgress: false, failed: false},
  describeVideo: {done: false, readyForPublishing: false},
  publishVideo: {done: false, inProgress: false},
};

export const defaultForm = {
  name: {error: null, value: ''},
  year: {error: null, value: (new Date()).getFullYear()},
  description: {error: null, value: ''},
  people: {error: null, value: []},
  places: {error: null, value: []},
  categories: {error: null, value: []},
  tags: {error: null, value: []},
};

const VideoUploader = (props) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [progresses, setProgresses] = useState({});
  const [uploadedQueueIds, setUploadedQueueIds] = useState([]);
  const [publishError, setPublishError] = useState(null);
  const [uploadErrorMessage, setUploadErrorMessage] = useState(null);

  const [steps, setSteps] = useState(defaultSteps);

  const [form, setForm] = useState(defaultForm);

  useEffect(() => {
    setSteps((prevState) => {
      return {...prevState, selectFiles: {...prevState.selectFiles, readyForUpload: !prevState.done && uploadedFiles.length > 0}}
    });
  }, [uploadedFiles]);

  useEffect(() => {
    if (steps.publishVideo.inProgress) {
      ajax.put('/api/v1/video', {
        queueIds: uploadedQueueIds,
        name: form.name.value,
        year: form.year.value,
        description: form.description.value,
        people: form.people.value,
        places: form.places.value,
        categories: form.categories.value,
        tags: form.tags.value,
      }, (data) => {
        setSteps((prevState) => {
          return {...prevState, publishVideo: {inProgress: false, done: true}}
        });
      }, (statusText) => {
        setSteps((prevState) => {
          return {...prevState, publishVideo: {inProgress: false, done: false}}
        });
        setPublishError(JSON.parse(statusText).message);
      });
    }
    // eslint-disable-next-line
  }, [steps.publishVideo]);

  useEffect(() => {
    if (steps.uploadFiles.inProgress) {
      const uploadAll = async () => {
        try {
          let token;
          for (const file of uploadedFiles) {
            token = await uploadFile(file, 3, (progress, done, id) => {
              if (id) {
                setUploadedQueueIds((prevState) => {
                  return [...prevState, id];
                });
              }
              setProgresses((prevState) => {
                return {...prevState, [file.name]: {progress, done}};
              });
            }, token);
            if (token === false) {
              throw Error(`${file.name}: Upload error.`);
            }
          }
        } catch (err) {
          throw Error(err.message);
        }
      }
      uploadAll().then(() => {
        setSteps((prevState) => {
          return {...prevState, uploadFiles: {done: true, inProgress: false, failed: false}}
        });
      }).catch((err) => {
        setUploadErrorMessage(err.message);
        setSteps((prevState) => {
          return {...prevState, uploadFiles: {done: false, inProgress: false, failed: true}}
        });
      });
    }
  }, [steps.uploadFiles, uploadedFiles]);

  function restart() {
    setSteps(defaultSteps);
    setForm(defaultForm);
    setPublishError(null);
    setUploadErrorMessage(null);
    setUploadedFiles([]);
    setProgresses({});
    setUploadedQueueIds([]);
  }

  function startUpload() {
    setSteps((previous) => {
      return {...previous, uploadFiles: {done: false, inProgress: true, failed: false}, selectFiles: {done: true, readyForUpload: false}};
    });
  }

  function nameIsValid(value) {
    return value.trim().length > 2;
  }

  function yearIsValid(value) {
    return isNumeric(value) && parseInt(value) > 1970 && parseInt(value) <= (new Date()).getFullYear();
  }

  function isFormValid() {
    const nameValid = nameIsValid(form.name.value);
    const yearValid = yearIsValid(form.year.value);

    return nameValid && yearValid;
  }

  function restartUploader() {
    setUploadErrorMessage(null);
    setUploadedFiles([]);
    setProgresses({});
    setUploadedQueueIds([]);
    setSteps((previous) => {
      return {...previous, selectFiles: {done: false, readyForUpload: false}, uploadFiles: {done: false, inProgress: false, failed: false}};
    });
  }

  function publish() {
    if (!isFormValid()) {
      setPublishError('Bitte die Pflichtfelder ausfüllen.');
      setSteps((prevState) => {
        return {...prevState, publishVideo: {inProgress: false, done: false}}
      });
    } else {
      setSteps((prevState) => {
        return {...prevState, describeVideo: {readyForPublishing: true, done: true}, publishVideo: {inProgress: true}}
      });
    }
  }

  const handleUploadFiles = files => {
    const uploaded = [...uploadedFiles];
    // eslint-disable-next-line
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });
    setUploadedFiles(uploaded)
  }

  const handleFileEvent =  (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    handleUploadFiles(chosenFiles);
  }

  const removeFile = (fileToDelete) => {
    setUploadedFiles(uploadedFiles.filter((file) => {
      return file !== fileToDelete;
    }));
  }

  const moveFile = (fileToMove, delta) => {
    setUploadedFiles((prevState) => {
      const newState = [...prevState];
      const index = newState.findIndex((candidate) => {
        return candidate === fileToMove;
      });
      if (index > -1) {
        const newIndex = index + delta;
        if (newIndex >= newState.length) {
          let k = newIndex - newState.length + 1;
          while (k--) {
            newState.push(undefined);
          }
        }
        newState.splice(newIndex, 0, newState.splice(index, 1)[0]);
      }

      return newState;
    });
  }

  if (steps.publishVideo.done) {
    return (
      <React.Fragment>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <DoneIcon color="#008000" /><div style={{paddingLeft: '10px', fontSize: '18px'}}>Erfolgreich! Das Video muss nun Verarbeitet werden. Du kannst den Status der Verarbeitung jederzeit auch bei deinen Videos einsehen.</div>
        </div>
        <Button variant="contained" color="secondary" size="large" href="/uploads" component="a" style={{marginTop: '20px', marginRight: '15px'}}>Zu meinen Videos</Button>
        <Button variant="contained" color="primary" size="large" onClick={restart} style={{marginTop: '20px'}}>Ein weiteres Video einstellen</Button>
      </React.Fragment>
    );
  }

  let uploader;
  if (steps.uploadFiles.failed) {
    uploader = (
      <React.Fragment>
        <Message type="error">{uploadErrorMessage}</Message>
        <Button variant="contained" color="secondary" size="medium" onClick={() => {restartUploader();}}>Nochmal versuchen</Button>
      </React.Fragment>
    );
  } else {
    let filesList = null;
    if (uploadedFiles.length > 0) {
      filesList = (
        <div className="uploaded-files-list" style={{marginBottom: !steps.selectFiles.done ? '20px' : '0', maxWidth: '700px', border: '1px solid #bdbdbd'}}>
          <List>
            {uploadedFiles.map((file, index) => {
              const done = progresses[file.name] ? progresses[file.name].done : false;
              return (
                <ListItem key={file.name}>
                  {done ? null : (
                    <ListItemAvatar>
                      <VideoPreview file={file}/>
                    </ListItemAvatar>
                  )}
                  <ListItemText
                    primary={file.name}
                    secondary={done ? 'Abgeschlossen' : null}
                  />
                  {!done ? (
                    <ListItemSecondaryAction>
                      {uploadedFiles.length > 1 && index > 0 ? (
                        <IconButton edge="end" title="Reihenfolge: Nach vorne" onClick={() => {moveFile(file, -1);}}>
                        <ArrowUpwardIcon />
                        </IconButton>
                        ) : null}
                      {uploadedFiles.length > 1 && index < uploadedFiles.length-1 ? (
                        <IconButton edge="end" title="Reihenfolge: Nach hinten" onClick={() => {moveFile(file, +1);}}>
                        <ArrowDownwardIcon />
                        </IconButton>
                        ) : null}
                        <IconButton edge="end" title="Entfernen" onClick={() => {removeFile(file);}}>
                        <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                  ) : null}
                </ListItem>
              )})}
          </List>
        </div>
      );
    }

    let fileInput = null;
    if (!steps.selectFiles.done) {
      fileInput = (
        <React.Fragment>
          <input id='fileUpload' type='file' multiple accept="video/*" style={{display: 'none'}} onChange={handleFileEvent} />
          <label htmlFor='fileUpload'>
            <Button variant="contained" color="secondary" size="medium" component="span">{uploadedFiles.length > 0 ? 'Weitere ' : null}Datei(en) auswählen</Button>
          </label>
        </React.Fragment>
      );
    }
    uploader = (
      <React.Fragment>
        {filesList}
        {fileInput}
      </React.Fragment>
    );
  }

  let overallUploadProgress = null;
  if (steps.uploadFiles.inProgress) {
    let percentageDone = 0.00;
    for (const progressFile of Object.values(progresses)) {
      percentageDone += progressFile.progress;
    }
    overallUploadProgress = (<LinearProgress variant="determinate" value={percentageDone / uploadedFiles.length} />);
  }

  const fieldsDisabled = steps.publishVideo.inProgress || steps.publishVideo.done;

  const multipleInfoText = uploadedFiles.length > 1 ? 'Du hast mehrere Videodateien ausgewählt. Sie werden in der angezeigten Reihenfolge zu einem einzigen Video zusammengefügt.' : 'Wenn du mehrere Videodateien auswählst, werden diese in der angezeigten Reihenfolge zu einem einzigen Video zusammengefügt.'

  return (
    <div className="VideoUploader">
      <form>
        <Explainer number={1} title="Wähle eine oder mehrere Video-Dateien aus" ready done={steps.selectFiles.done}>
          {uploader}
          <div style={{marginTop: '20px'}}>
            <Info highlight={uploadedFiles.length > 1}>{multipleInfoText}</Info>
          </div>
        </Explainer>

        <Explainer number={2} title="Lade die Videodateien hoch" ready={steps.selectFiles.readyForUpload  || steps.selectFiles.done} done={steps.uploadFiles.done}>
          {steps.uploadFiles.inProgress ? (
            <React.Fragment>
              <p>Wird hochgeladen...</p>
              {overallUploadProgress}
            </React.Fragment>
          ) : (
            <React.Fragment>
              { steps.uploadFiles.done ?
                (<div style={{display: 'flex', alignItems: 'center'}}><DoneIcon /><div style={{paddingLeft: '10px'}}>Alles erfolgreich hochgeladen!</div></div>) :
                (<Button variant="contained" color="secondary" size="medium" disabled={steps.uploadFiles.failed} onClick={() => {startUpload();}}>Hochladen starten</Button>)
              }
            </React.Fragment>
          )}
        </Explainer>

        <Explainer number={3} title="Beschreibe das Video" ready={steps.uploadFiles.done} done={steps.describeVideo.done}>
          <Info>Die mit "*" gekennzeichneten Felder sind Pflicht. Bitte hinterlege auch vorkommende Personen, Orte, ... Damit können die Videos besser gefiltert werden.</Info>
          <VideoOrGalleryDetails form={form} setForm={setForm} fieldsDisabled={fieldsDisabled} nameFieldTitle='Titel des Videos' />
        </Explainer>

        <Explainer number={4} title="Veröffentliche das Video" ready={form.name.value.trim() !== '' || steps.describeVideo.done} last  done={steps.publishVideo.done}>
          <Info>Das Video muss danach noch für die Wiedergabe vorbereitet werden. Du kannst den Status bei den Uploads verfolgen. Du hast dann außerdem die Möglichkeit, das Vorschaubild zu ändern und Kapitelmarken hinzuzufügen.</Info>
          <div>
            {steps.publishVideo.done ? (
              <React.Fragment>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <DoneIcon color="green" /><div style={{paddingLeft: '10px', fontSize: '18px'}}>Erfolgreich! Du kannst den Status der Verarbeitung bei deinen Videos einsehen.</div>
                </div>
                <Button variant="contained" color="secondary" size="large" href="/uploads" component="a" style={{marginTop: '15px'}}>Zu meinen Videos</Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {publishError ? (<Message type="error">{publishError}</Message>) : null}
                {steps.publishVideo.inProgress ? (
                  <React.Fragment>
                    <Button variant="contained" color="primary" size="large" disabled>Wird veröffentlicht...</Button>
                    <LinearProgress />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button variant="contained" color="primary" size="large" onClick={() => {publish();}}>Video veröffentlichen</Button>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </Explainer>
      </form>
    </div>
  );
}

export default VideoUploader;
