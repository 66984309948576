import React, {useState} from "react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ControlIcon from "./ControlIcon";

const Quality = ({qualityOptions, setHidingControlsPrevented}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setHidingControlsPrevented(true);
        setAnchorEl(event.currentTarget);
        document.body.classList.add('hasVideoOverlay');
    };

    const handleClose = () => {
        setHidingControlsPrevented(false);
        setAnchorEl(null);
        document.body.classList.remove('hasVideoOverlay');
    };

    if (!qualityOptions) {
      return null;
    }

    if (qualityOptions.length < 2) {
        return null;
    }

    const ITEM_HEIGHT = 48;

    return (
        <div className="quality">
            <ControlIcon clickHandler={handleClick} aria-label="Quality" aria-controls="Quality" aria-haspopup="true"><MoreVertIcon /></ControlIcon>
            <Menu
                id="Quality"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 200,
                    },
                }}
            >
                {qualityOptions.map(quality => (
                    <MenuItem key={quality.value} selected={quality.active} onClick={() => {quality.setter();handleClose();}}>
                        {quality.name}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default Quality;
