import React from "react";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ControlIcon from "./ControlIcon";

const FullScreen = ({fullscreen, toggleFullscreen}) => {
    if (fullscreen) {
        return (
            <div>
                <ControlIcon clickHandler={toggleFullscreen} aria-label="Vollbild verlassen"><FullscreenExitIcon /></ControlIcon>
            </div>
        );
    }

    return (
        <div>
            <ControlIcon clickHandler={toggleFullscreen} aria-label="Vollbild"><FullscreenIcon /></ControlIcon>
        </div>
    );
};

export default FullScreen;
