import React, {useEffect, useRef, useState} from 'react';
import {MediaPlayer} from "dashjs";
import {ajaxConfig, getToken} from "../../../ajax/ajax";
import Controlbar from "./Controls/Controlbar";
import './UpdatedPlayer.scss';
import BigPlayButton from "./Controls/BigPlayButton";
import PlaybackEnded from "./PlaybackEnded";
import PlayStateIndicator from "./PlayStateIndicator";
import isTouchDevice from "../../../util/isTouchDevice";
import {isMobile} from "../../../util/breakpoints";

function getVolumeFromLocalStorage() {
  return parseFloat(localStorage.getItem('volume') ?? '1');
}

function setVolumeInLocalStorage(volume) {
  return localStorage.setItem('volume', volume.toString());
}

async function launchIntoFullscreen(element) {
  if (element.requestFullscreen) {
    return element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    return element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    return element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    return element.msRequestFullscreen();
  }

  element.classList.add('fullscreen');
  return true;
}

async function exitFullscreen(element) {
  if (document.exitFullscreen) {
    return document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    return document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    return document.webkitExitFullscreen();
  }

  element.classList.remove('fullscreen');
  return true;
}

let timeoutIndicator = null;
let timeoutControls = null;

const touchDevice = isTouchDevice();

const UpdatedPlayer = ({url, autoPlay, onPlayHeadTimeChanged, markers, seekTo, showControlsOnlyOnHover, showOverlayAfterPlaybackEnded, videoId}) => {
  const mobile = isMobile();
  const video = useRef();
  const player = useRef(null);
  const parent = useRef(null);
  const [canPlay, setCanPlay] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [playbackNotAllowed, setPlaybackNotAllowed] = useState(false);
  const [playbackEnded, setPlaybackEnded] = useState(false);
  const [timePlayedFormatted, setTimePlayedFormatted] = useState('00:00');
  const [timePlayed, setTimePlayed] = useState(0);
  const [timeBuffered, setTimeBuffered] = useState(0);
  const [durationFormatted, setDurationFormatted] = useState('00:00');
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(mobile ? 1 : getVolumeFromLocalStorage());
  const [muted, setMuted] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [qualityOptions, setQualityOptions] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [controlsAreShown, setControlsAreShown] = useState(!showControlsOnlyOnHover);
  const [hidingControlsPrevented, setHidingControlsPrevented] = useState(false);
  const [indicatorShown, setIndicatorShown] = useState(false);

  function isFullscreen() {
    const fullScreenElement = document.fullscreenElement || document.msFullscreenElement || document.mozFullScreen || document.webkitIsFullScreen;

    return fullScreenElement === video.current;
  }

  const togglePlayPause = () => {
    if (null !== player.current) {
      player.current.isPaused() ? player.current.play() : player.current.pause();
      clearTimeout(timeoutIndicator);
      setIndicatorShown(true);
      timeoutIndicator = setTimeout(() => {
        setIndicatorShown(false);
      }, 300);
    }
  }

  function updateHidingControlsPrevented(isPrevented) {
    if (false === isPrevented) {
      clearTimeout(timeoutControls);
      setControlsAreShown(true);
      timeoutControls = setTimeout(() => {
        if (showControlsOnlyOnHover) {
          setControlsAreShown(false);
        }
      }, 2000);
    }
    setHidingControlsPrevented(isPrevented);
  }

  function toggleMute(mute) {
    if (undefined === mute) {
      setMuted(!muted);
    } else {
      setMuted(mute);
    }
  }

  function toggleFullscreen() {
    if (video.current) {
      if (!fullscreen) {
        launchIntoFullscreen(video.current).then(() => {
          setFullscreen(true);
        }).catch(() => {
          setFullscreen(false);
        });
      } else {
        exitFullscreen(video.current).then(() => {
          setFullscreen(isFullscreen());
        }).catch(() => {
          setFullscreen(isFullscreen());
        });
      }
    }
  }

  function movePlayHead(time) {
    if (player.current) {
      setPlaybackEnded(false);
      player.current.seek(time);
    }
  }

  const previewThumbnail = (time) => {
    if (null === time) {
      setThumbnail(null);
      return;
    }
    if (!player.current.provideThumbnail) {
      setThumbnail(null);
      return;
    }
    player.current.provideThumbnail(time, (thumbnail) => {
      if (!thumbnail) {
        setThumbnail(null);
        return;
      }

      thumbnail.url += '?token=' + ajaxConfig.token;
      thumbnail.time = time;
      thumbnail.timeFormatted = player.current.convertToTimeCode(time);
      setThumbnail(thumbnail);
    });
  };

  function updateMaxHeight(videoElem) {
    let headerHeight = 0;
    const header = document.querySelector('.Layout__header');
    if (header) {
      headerHeight = header.getBoundingClientRect().height;
    }

    videoElem.style.maxHeight = `${window.innerHeight - headerHeight}px`;
  }

  useEffect(() => {
    const handler = () => {
      if (video.current) {
        updateMaxHeight(video.current);
      }
    }
    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    }
  }, []);

  useEffect(() => {
    if (!video.current || !url) {
      return;
    }

    updateMaxHeight(video.current);
    player.current = MediaPlayer().create();
    player.current.extend("RequestModifier", () => {
        return {
          modifyRequestHeader: xhr => {
            xhr.setRequestHeader('X-AUTH-TOKEN', getToken());
            return xhr;
          }
        };
      },
      true
    );
    player.current.initialize(video.current, url, !!autoPlay);
    player.current.setVolume(volume);

    const prepareQualityMenu = () => {
      const audioBitrates = player.current.getBitrateInfoListFor('audio') || [];
      const videoBitrates = player.current.getBitrateInfoListFor('video') || [];
      const settings = player.current.getSettings();
      if (videoBitrates.length > 1) {
        const currentIndex = player.current.getQualityFor('video');
        const options = [{
          name: 'Automatisch',
          value: 'auto',
          active: settings.streaming.abr.autoSwitchBitrate['video'] === true,
          audioIndex: 'auto',
          setter: () => {
            setQuality('auto', 'auto');
          }
        }];
        for (let index in videoBitrates) {
          let audioIndex = null;
          if (audioBitrates.length > 0) {
            if (undefined !== audioBitrates[index]) {
              audioIndex = index;
            } else {
              audioIndex = audioBitrates.length - 1;
            }
          }

          const optionIsActive = parseInt(index) === currentIndex;
          if (optionIsActive && true === options[0].active) {
            //auto
            options[0].name += ` (${videoBitrates[index].height + 'p'})`;
          } else {
            options.push({
              name: videoBitrates[index].height + 'p',
              value: index,
              active: parseInt(index) === currentIndex,
              audioIndex: audioIndex,
              setter: () => {
                setQuality(index, audioIndex);
              }
            });
          }
        }
        setQualityOptions(options);
      }
    };

    const setQuality = (videoQuality, audioQuality) => {
      const cfg = {
        'streaming': {
          'abr': {
            'autoSwitchBitrate': {
            }
          }
        }
      };

      if (null !== videoQuality) {
        if ('auto' === videoQuality) {
          cfg.streaming.abr.autoSwitchBitrate['video'] = true;
          player.current.updateSettings(cfg);
        } else {
          cfg.streaming.abr.autoSwitchBitrate['video'] = false;
          player.current.updateSettings(cfg);
          player.current.setQualityFor('video', parseInt(videoQuality));
        }
      }
      if (null !== audioQuality) {
        if ('auto' === audioQuality) {

          player.current.updateSettings(cfg);
        } else {
          cfg.streaming.abr.autoSwitchBitrate['audio'] = false;
          player.current.updateSettings(cfg);
          player.current.setQualityFor('audio', parseInt(audioQuality));
        }
      }

      prepareQualityMenu();
    };

    player.current.on(MediaPlayer.events.STREAM_INITIALIZED, (e) => {
      setDuration(e.streamInfo.duration);
      setDurationFormatted(player.current.convertToTimeCode(e.streamInfo.duration));
      prepareQualityMenu();
    }, this);
    player.current.on(MediaPlayer.events.CAN_PLAY, (e) => {
      setCanPlay(true);
    }, this);
    player.current.on(MediaPlayer.events.PLAYBACK_TIME_UPDATED, (e) => {
      setTimePlayed(e.time);
      setTimeBuffered(player.current.getBufferLength() + e.time);
      setTimePlayedFormatted(player.current.convertToTimeCode(e.time));
      onPlayHeadTimeChanged(e.time);
    }, this);
    player.current.on(MediaPlayer.events.PLAYBACK_STARTED, (e) => {
      setPlaybackNotAllowed(false);
      setTimePlayedFormatted(player.current.convertToTimeCode(e.startTime));
      setIsPaused(false);
      setPlaybackEnded(false);
      parent.current.focus();
    }, this);
    player.current.on(MediaPlayer.events.PLAYBACK_PAUSED, (e) => {
      setIsPaused(true);
      setPlaybackEnded(e.ended);
      parent.current.focus();
    }, this);
    player.current.on(MediaPlayer.events.PLAYBACK_NOT_ALLOWED, (e) => {
      setPlaybackNotAllowed(true);
    }, this);
    player.current.on(MediaPlayer.events.QUALITY_CHANGE_REQUESTED, (e) => {
      prepareQualityMenu();
    }, this);
    player.current.on(MediaPlayer.events.QUALITY_CHANGE_RENDERED, (e) => {
      prepareQualityMenu();
    }, this);
    player.current.on(MediaPlayer.events.PLAYBACK_ENDED, (e) => {
      setPlaybackEnded(true);
      setIsPaused(true);
    }, this);

    function onFullscreenChange(e) {
      if (e.target === video.current) {
        setFullscreen(isFullscreen());
      }
    }

    function onKeyPress(e) {
      if (e.code === "Space") {
        togglePlayPause();
        e.preventDefault();
        e.stopPropagation();
      } else if (e.code === "ArrowRight") {
        movePlayHead(player.current.time() + 5);
        e.preventDefault();
        e.stopPropagation();
      } else if (e.code === "ArrowLeft") {
        movePlayHead(player.current.time() - 5);
        e.preventDefault();
        e.stopPropagation();
      }
    }

    const videoTarget = video.current;
    const parentTarget = parent.current;
    videoTarget.addEventListener('fullscreenchange', onFullscreenChange);
    parentTarget.addEventListener('keydown', onKeyPress);

    return () => {
      videoTarget.removeEventListener('fullscreenchange', onFullscreenChange);
      parentTarget.removeEventListener('keydown', onKeyPress);
      player.current.destroy();
    };
  }, [video, url, autoPlay]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (player.current) {
      player.current.setVolume(volume);
      player.current.setMute(muted);
    }
  }, [volume, muted]);

  useEffect(() => {
    if (player.current && canPlay && seekTo) {
      player.current.seek(seekTo);
    }
  }, [canPlay, seekTo]);

  useEffect(() => {
    if (!showControlsOnlyOnHover) {
      return;
    }
    function mouseEnter(e) {
      setControlsAreShown(true);
    }
    function mouseLeave(e) {
      setControlsAreShown(false);
    }

    function touchClick(e) {
      clearTimeout(timeoutControls);
      setControlsAreShown(true);
      timeoutControls = setTimeout(() => {
        setControlsAreShown(false);
      }, 3000);
    }

    const playerRef = parent.current;
    if (playerRef) {
      if (touchDevice) {
        playerRef.addEventListener('click', touchClick);
      } else {
        playerRef.addEventListener('mouseenter', mouseEnter);
        playerRef.addEventListener('mouseleave', mouseLeave);
      }
    }

    return () => {
      if (playerRef) {
        if (touchDevice) {
          playerRef.removeEventListener('click', touchClick);
        } else {
          playerRef.removeEventListener('mouseenter', mouseEnter);
          playerRef.removeEventListener('mouseleave', mouseLeave);
        }

      }
    }
  }, [showControlsOnlyOnHover]);

  return (
    <div className="UpdatedPlayer" ref={parent} tabIndex={1}>
      <video ref={video} autoPlay={autoPlay} preload="true" style={{width: '100%'}} onClick={() => {if (!touchDevice) {togglePlayPause();}}}></video>
      <BigPlayButton show={canPlay && playbackNotAllowed} play={togglePlayPause} />
      {showOverlayAfterPlaybackEnded ? (<PlaybackEnded show={playbackEnded} play={togglePlayPause} videoId={videoId} />) : null}
      <PlayStateIndicator show={indicatorShown} isPLaying={!isPaused}/>
      <div className="UpdatedPlayer__controls">
        <Controlbar
          hide={!controlsAreShown && !hidingControlsPrevented} setHidingControlsPrevented={updateHidingControlsPrevented}
          isPaused={isPaused} togglePlayPause={togglePlayPause}
          durationFormatted={durationFormatted} timePlayedFormatted={timePlayedFormatted}
          volume={volume} muted={muted} setVolumeHandler={(volume) => {setVolume(volume);}} setVolumeInLocalStorage={setVolumeInLocalStorage} toggleMute={toggleMute}
          fullscreen={fullscreen} toggleFullscreen={toggleFullscreen}
          qualityOptions={qualityOptions}
          positionPlayHead={timePlayed} positionBufferHead={timeBuffered} duration={duration} movePlayHead={movePlayHead} onPreviewSeek={previewThumbnail} thumbnail={thumbnail} markers={markers}
        />
      </div>
    </div>
  );
}

export default UpdatedPlayer;
