import React from 'react';

import './Message.scss';
import ErrorIcon from '@material-ui/icons/Error';
import SnackbarContent from "@material-ui/core/SnackbarContent";
import {makeStyles} from "@material-ui/core";
import {Close} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    errorSnackbar: {
        backgroundColor: theme.palette.error.dark,
        marginBottom: theme.spacing(4),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    messageIcon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
}));

const Message = ({type, children, close}) => {

    const classes = useStyles();

    const classNames = ['Message'];
    switch(type) {
        case 'error':
            return (
                <SnackbarContent className={classes.errorSnackbar}
                                 message={<span className={classes.message}>
          <ErrorIcon className={classes.messageIcon}/>
                                     {children}
                                     {close ? (<Close onClick={() => {close();}} />) : null}
        </span>}
                                 role="alert"
                />
            );
        case 'success':
            classNames.push('Message--success');
            break;
        default:
            break;
    }
    return (
      <div className={classNames.join(' ')}>
          {children}
          {close ? (<Close onClick={() => {close();}} />) : null}
      </div>
    );
}

export default Message;
