import React, {useState} from 'react';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from "@material-ui/core/IconButton";
import './TextInputWithConfirmCancel.scss';
import {Add, Edit} from "@material-ui/icons";

const TextInputWithConfirmCancel = ({label, onConfirm, confirmLabel, onCancel, edit, initialValue}) => {
  const [value, setValue] = useState(initialValue ?? '');

  function onKeyDown(e) {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      if (value.trim() !== '') {
        if (onConfirm(value)) {
          setValue('');
        }
      }
    } else if (e.key === 'Escape') {
      onCancel(); setValue('');
    }
  }

  return (
    <div className="TextInputWithConfirmCancel">
      {edit ? (<Edit />) : (<Add />)}
      <div className="TextInputWithConfirmCancel__input">
        <input type="text" value={value} placeholder={label} onInput={(e) => {setValue(e.target.value);}} onKeyDown={onKeyDown} />
      </div>
      {value.trim() !== '' ? (
        <div className="TextInputWithConfirmCancel__icons">
          <IconButton title={confirmLabel} size="small" onClick={(e) => {if(onConfirm(value)) {setValue('');} }}>
            <CheckIcon />
          </IconButton>
          <IconButton title="Abbrechen" size="small" onClick={(e) => {onCancel(); setValue('');}}>
            <ClearIcon />
          </IconButton>
        </div>
      ) : null}
    </div>
  );
}

export default TextInputWithConfirmCancel;
