import React from 'react';
import FilterHeader from './FilterHeader';
import LinkStyleButton from '../UI/Button/LinkStyleButton';

import './FilterItemTerms.scss';

const FilterItemTerms = (props) => {

    if (props.values.length === 0) {
        return null;
    }

    const params = new URLSearchParams(props.queryString);    
    const activeValuesFromQuery = params.getAll(props.id + '[]');
    const activeValues = [];
    const values = [];
    for (let value of props.values) {
        if (activeValuesFromQuery.indexOf(value.value) !== -1) {
            activeValues.push(value);
        } else {
            values.push(value);
        }
    }

    let valuesContent = null;
    if (values.length > 0) {
        valuesContent = (
            <ul>
                {values.map((value) => {
                    const params = new URLSearchParams(props.queryString);
                    params.append(props.id + '[]', value.value);
                    
                    return (<li className="FilterItem__value" key={value.value}>
                        <LinkStyleButton onClick={(e) => {
                            e.preventDefault();
                            props.changeQueryHandler(params.toString());
                        }}>{ value.value } ({ value.count })</LinkStyleButton>
                       </li>)
                })}
            </ul>
        );
    }

    let activeValuesContent = null;
    if (activeValues.length > 0) {
        activeValuesContent = (
            <div className="FilterItem__activeValues">
                {activeValues.map((value) => {
                    const params = new URLSearchParams(props.queryString);
                    const thenActiveValues = [...activeValuesFromQuery];
                    thenActiveValues.splice(thenActiveValues.indexOf(value.value), 1);
                    params.delete(props.id + '[]');
                    for (let thenActiveValue of thenActiveValues) {
                        params.append(props.id + '[]', thenActiveValue);
                    }

                    return (
                    <span key={value.value}>
                        <LinkStyleButton className="removeValue" onClick={(e) => {
                            e.preventDefault();
                            props.changeQueryHandler(params.toString());
                        }}>&times; { value.value }</LinkStyleButton>
                    </span>)
                })}
            </div>
        );
    }

    let removeFilterHandler = null;
    if (activeValues.length > 0) {
        removeFilterHandler = () => {
            const params = new URLSearchParams(props.queryString);
            params.delete(props.id + '[]');
    
            props.changeQueryHandler(params.toString());
        }
    }

    return (
        <React.Fragment>
            <FilterHeader title={props.title} removeFilterHandler={removeFilterHandler} />
            {activeValuesContent}
            {valuesContent}
        </React.Fragment>
    );
};

export default FilterItemTerms;