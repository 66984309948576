import {useReducer, useEffect} from 'react';
import useFavorites from "./useFavorites";
import ajax from '../ajax/ajax';

const useVideos = (queryString) => {

    const initialState = {
        queryString: null,
        videos: [],
        filter: [],
        loading: true,
        error: null
    };

    const [favorites, addFavorite, removeFromFavorites] = useFavorites();

    const [videos, dispatch] = useReducer((previousState, action) => {
        switch (action.type) {
            case 'FETCH_VIDEOS':
                return {
                    ...previousState,
                    queryString: action.queryString,
                    loading: true, 
                    error: null
                };
            case 'FETCH_VIDEOS_SUCCESS':
                return {
                    videos: action.videos,
                    filter: action.filter,
                    loading: false,
                    error: null,
                    queryString: previousState.queryString
                };
            case 'FETCH_VIDEOS_FAILURE':
                return {
                    ...previousState, 
                    loading: false,
                    error: action.error
                };
            case 'VIDEOS_CHANGE_QUERY':
                let queryString = action.queryString;
                if (queryString.charAt(0) !== '?') {
                    queryString = '?' + queryString;
                }
                return {
                    ...previousState, 
                    loading: false,
                    queryString: queryString
                };
            default:
                return previousState
        }
    }, initialState);

    useEffect(() => {

        if (videos.queryString === null) {
            return;
        }

        const {cancel} = ajax.get('/api/v1/videos' + videos.queryString, (response) => {
            dispatch({
                type: 'FETCH_VIDEOS_SUCCESS',
                videos: response.videos,
                filter: response.filter
            });
        }, (error, status) => {
            let errorMessage = 'Server nicht erreichbar.';
            if (error) {
                errorMessage = error;
            }

            if (status === 401) {
                errorMessage = 'Login erforderlich.';
            }

            dispatch({
                type: 'FETCH_VIDEOS_FAILURE',
                error: errorMessage
            });
        });

        return () => {
            cancel();
        }
    }, [videos.queryString]);

    const setQueryString = (newQueryString) => {
        dispatch({
            type: 'VIDEOS_CHANGE_QUERY',
            queryString: newQueryString
        });
    };

    if (queryString !== videos.queryString) {
        dispatch({
            type: 'FETCH_VIDEOS',
            queryString: queryString
        });
    }

    for (let video of videos.videos) {
        video.isFavorite = null !== favorites && favorites.indexOf(video.id) > -1;
        video.setIsFavorite = () => {
            addFavorite(video.id);
        };
        video.removeFromFavorites = () => {
            removeFromFavorites(video.id);
        };
    }

    return [videos, setQueryString];
};

export default useVideos;