import React from "react";
import Avatar from "@material-ui/core/Avatar";
import {personsWithImages} from "./PersonAvatar";
import Chip from "@material-ui/core/Chip";

const PersonAvatarFull = ({personName, onDelete}) => {
    if (personsWithImages.indexOf(personName) > -1) {
        return (<Chip
          variant="outlined"
          size="medium"
          avatar={<Avatar alt={personName} src={"/static/images/avatar/"+personName+".jpg"} />}
          label={personName}
          onDelete={onDelete}
        />);
    }
    return (
      <Chip
        variant="outlined"
        size="medium"
        avatar={<Avatar>{personName[0]}</Avatar>}
        label={personName}
        onDelete={onDelete}
      />
    );
};

export default PersonAvatarFull;
