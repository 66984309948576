import React, {useState} from 'react';
import Layout from '../Layout/Layout';
import Filter from '../../components/Filter/Filter';
import {useLocation, useHistory} from 'react-router-dom';
import VideoList from '../../components/VideoList/VideoList';
import useVideos from '../../hooks/useVideos';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {Accordion, AccordionDetails, AccordionSummary, Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {isMobileOrTablet} from "../../util/breakpoints";
import FilterListIcon from '@material-ui/icons/FilterList';
import useGalleries from "../../hooks/useGalleries";
import GalleriesList from "../../components/Gallery/GalleriesList";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function findFilterById(filter, id) {
  return filter.find((candidate) => {
    return candidate.id === id;
  });
}

function combineFilters(filterVideos, filterGalleries) {
  const filters = ['places', 'year', 'categories', 'people'];
  const combinedFilter = [];

  for (const filterId of filters) {
    const itemFromVideos = findFilterById(filterVideos, filterId);
    const itemFromGalleries = findFilterById(filterGalleries, filterId);
    if (itemFromVideos && itemFromGalleries) {
      if (itemFromVideos.type === 'slider') {
        const combined = {
          id: itemFromVideos.id,
          title: itemFromVideos.title,
          type: itemFromVideos.type,
          values: {min: Math.min(itemFromVideos.values.min, itemFromGalleries.values.min), max: Math.max(itemFromVideos.values.max, itemFromGalleries.values.max)},
        };
        combinedFilter.push(combined);
      } else {
        const combined = {
          id: itemFromVideos.id,
          title: itemFromVideos.title,
          type: itemFromVideos.type,
          values: itemFromVideos.values.map((value) => {
            return {...value};
          }),
        };
        for (const value of itemFromGalleries.values) {
          const existingIndex = combined.values.findIndex((candidate) => {
            return candidate.value === value.value;
          });
          if (existingIndex > -1) {
            combined.values[existingIndex] = {...combined.values[existingIndex], count: combined.values[existingIndex].count + value.count};
          } else {
            combined.values.push(value);
          }
        }
        combined.values.sort((a, b) => {
          return b.count - a.count;
        });
        combinedFilter.push(combined);
      }
    } else if (itemFromVideos) {
      combinedFilter.push(itemFromVideos);
    } else if (itemFromGalleries) {
      combinedFilter.push(itemFromGalleries);
    }
  }

  return combinedFilter;
}

const Search = () => {
  const location = useLocation();
  const queryString = location.search;
  const [videos, setQueryString] = useVideos(queryString);
  const [loading, error, galleryResponse, setQueryStringGalleries, usedQueryString] = useGalleries(queryString);
  const [sidebarShown, setSidebarShown] = useState(!isMobileOrTablet());
  const [videosExpanded, setVideosExpanded] = React.useState(true);
  const [galleriesExpanded, setGalleriesExpanded] = React.useState(true);

  const history = useHistory();

  const changeQueryHandler = (newQueryString) => {
    if (newQueryString.charAt(0) !== '?') {
      newQueryString = '?' + newQueryString;
    }
    history.push(location.pathname + newQueryString);
    setQueryString(newQueryString);
    setQueryStringGalleries(newQueryString);
  };

  let filters = [];
  if (!loading && !videos.loading) {
    filters = combineFilters(videos.filter, galleryResponse.filter);
  }

  const additionalSidebar = (
    <React.Fragment>
      <Divider/>
      {filters ? <Filter filter={filters} queryString={videos.queryString} changeQueryHandler={changeQueryHandler} /> : null}
    </React.Fragment>
  );

  const params = new URLSearchParams(queryString);
  let searchQuery = params.get('q');

  const headline = 'Suche nach ' + searchQuery;

  return (
    <Layout additionalNavigation={additionalSidebar} sidebarShown={sidebarShown} setSidebarShown={setSidebarShown} sidebarScrollTo={isMobileOrTablet() ? ".Filter" : undefined}>
      <div className="Videos" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '0.5em'}}>
        <div>
          <Typography variant="h1" component="h1">
            {headline}
          </Typography>
        </div>
        {sidebarShown ? null : (<FilterListIcon onClick={() => {setSidebarShown(true);}} />)}
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <SortIcon />
          <div style={{marginLeft: '5px'}}>
            <Select
              value={params.get('sort') ?? 'date-desc'}
              onChange={(e) => {
                params.set('sort', e.target.value);
                changeQueryHandler(params.toString());
              }}
            >
              <MenuItem className="selectWithIcon" value="date-desc">Jahr <ArrowDownwardIcon /></MenuItem>
              <MenuItem className="selectWithIcon" value="date-asc">Jahr <ArrowUpwardIcon /></MenuItem>
              <MenuItem className="selectWithIcon" value="duration-desc">Dauer <ArrowDownwardIcon /></MenuItem>
              <MenuItem className="selectWithIcon" value="duration-asc">Dauer <ArrowUpwardIcon /></MenuItem>
              <MenuItem className="selectWithIcon" value="recently-added">Neueste <ArrowDownwardIcon /></MenuItem>
            </Select>
          </div>
        </div>
      </div>

      <Accordion expanded={videosExpanded} onChange={() => {setVideosExpanded(!videosExpanded)}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="h2" component="h2">Videos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <VideoList {...videos} />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={galleriesExpanded} onChange={() => {setGalleriesExpanded(!galleriesExpanded)}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="h2" component="h2">Galerien</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GalleriesList galleries={galleryResponse.galleries} />
        </AccordionDetails>
      </Accordion>
    </Layout>
  );

};

export default Search;
