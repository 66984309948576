import React from 'react';
import './ProgressBarThumbnail.scss';

const ProgressBarThumbnail = ({thumbnail, containerWidth, duration}) => {
    if (thumbnail === null) {
        return null;
    }

    const max = containerWidth - thumbnail.width;
    let left = thumbnail.time / duration * containerWidth - (thumbnail.width/2);
    if (left < 0) {
        left = 0;
    } else if(left > max) {
        left = max;
    }

    const style = {
        backgroundImage: `url(${thumbnail.url})`,
        backgroundPosition: (thumbnail.x > 0 ? '-' + thumbnail.x : '0') + 'px ' + (thumbnail.y > 0 ? '-' + thumbnail.y : '0') + 'px',
        width:  thumbnail.width + 'px',
        height:  thumbnail.height + 'px',
        left:  left + 'px',
    };

    return (
        <div className="ProgressBarThumbnail" style={style}>
            <div className="ProgressBarThumbnail__time"><span>{thumbnail.timeFormatted}</span></div>
        </div>
    );
};

export default ProgressBarThumbnail;
