import {useEffect, useState} from "react";
import ajax from '../ajax/ajax';

let favorites = null;
let listeners = [];

const pushStateToListeners = (newFavorites) => {
    favorites = newFavorites;
    for (let listener of listeners) {
        listener(favorites);
    }
};

const useFavorites = () => {

    const setFavorites = useState(favorites)[1];

    useEffect(() => {
        if (null === favorites) {
            const {cancel} = ajax.get('/api/v1/favorites', (favorites) => {
                pushStateToListeners(favorites);
            }, (error) => {
                console.error(error);
            });

            return () => {
                cancel();
            }
        }
    }, []);

    useEffect(() => {
        listeners.push(setFavorites);

       return () => {
           const index = listeners.indexOf(setFavorites);
           listeners.splice(index, 1);
       }
    });

    const addFavorite = (video) => {
        ajax.put('/api/v1/favorites', {video: video});
        const newFavorites = [...favorites];
        if (newFavorites.indexOf(video) === -1) {
            newFavorites.push(video);
        }
        pushStateToListeners(newFavorites);
    };
    const removeFromFavorites = (video) => {
        ajax.delete('/api/v1/favorites/' + encodeURIComponent(video));
        const index = favorites.indexOf(video);
        const newFavorites = [...favorites];
        newFavorites.splice(index, 1);
        pushStateToListeners(newFavorites);
    };

    return [favorites, addFavorite, removeFromFavorites];
};

export default useFavorites;