import React from 'react';
import './VideoTeaserItem.scss';
import urlFriendlyName from "../../../util/urlFriendlyName";
import {useHistory} from "react-router-dom";
import {getToken} from "../../../ajax/ajax";
import Duration from "../VideoDuration";
import FavoriteButton from "../../FavoriteButton/FavoriteButton";

const VideoTeaserItem = (props) => {
    const history = useHistory();
    const url = '/video/' + encodeURIComponent(urlFriendlyName(props.title)) + '_' + props.id.toString();

    return (
        <div className="VideoTeaserItem">

            <a href={url} onClick={(e) => {e.preventDefault(); history.push(url);}}>
                <div className="VideoTeaserItem__image">
                    <img src={props.thumbnail + '&token='+encodeURIComponent(getToken())} alt="" loading="lazy" />
                    <div className="VideoTeaserItem__duration"><Duration seconds={props.duration}/></div>
                </div>
                <div className="VideoTeaserItem__title" data-title={ props.title }>{ props.title }</div>
                <div className="VideoTeaserItem__year">{ props.year.join('/')}</div>
                <FavoriteButton isFavorite={props.isFavorite} addToFavorites={props.setIsFavorite} removeFromFavorites={props.removeFromFavorites}/>
            </a>

        </div>
    );
};

export default VideoTeaserItem;
