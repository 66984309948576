import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Logo from '../Logo/Logo';
import {withRouter} from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

import './Header.scss';
import PersonAvatar from "../PersonAvatar/PersonAvatar";
import IconButton from "@material-ui/core/IconButton";

const Header = ({location, history, searchToggleHandler, userName, handleLogout, toggleMenu, menuOpen}) => {

    const params = new URLSearchParams(location.search);
    let searchQuery = params.get('q');
    const [searchFieldValue, setSearchFieldValue] = useState(searchQuery !== null ? searchQuery : '');

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let searchQuery = params.get('q');
        setSearchFieldValue(null !== searchQuery ? searchQuery : '');

    }, [location]);

    return (
        <header className="Header">
            <div className="Header__main">
                <IconButton title="Navigationsmenü" onClick={toggleMenu}>
                    {menuOpen ? <MenuOpenIcon /> : <MenuIcon />}
                </IconButton>
                <a className="Header__logo" href="/">
                    <Logo />
                </a>
                <div className="Header__search">
                    <span className="Header__search__close" onClick={searchToggleHandler}><ArrowBackIcon/></span>
                    <form onSubmit={(e) => {
                        e.preventDefault(); history.push('/search?q='+encodeURIComponent(searchFieldValue))
                    }}>
                        <input type="text" name="q" placeholder="Suchen..." value={searchFieldValue} onChange={(e) => {setSearchFieldValue(e.target.value)}} aria-label="Suche"/>
                        <button type="submit" aria-label="Suchen">
                            <SearchIcon style={{color: '#8b8b8b'}}/>
                        </button>
                    </form>
                </div>
            </div>
            <div className="Header__user">
                <span className="Header__user__searchIcon" onClick={searchToggleHandler}><SearchIcon style={{color: '#8b8b8b', width: 30, height: 30}}/></span>
                <PersonAvatar personName={userName}/>
                <div className="Header__user__name">
                    {userName}
                    <div className="Header__user__logout" onClick={handleLogout}>Logout</div>
                </div>
            </div>
        </header>
    );
};

const stateToProps = (state) => {
    return {
        userName: state.auth.userName
    };
};

const actionsToProps = (dispatch) => {
    return {
        handleLogout: () => {
            dispatch({
                type: 'AUTH_CLEAR'
            });
        }
    }
};

export default withRouter(connect(stateToProps, actionsToProps)(Header));
