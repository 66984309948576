import React from 'react';
import Spinner from "../UI/Spinner/Spinner";
import Message from "../UI/Message/Message";
import LinearProgress from "@material-ui/core/LinearProgress";
import ReplayIcon from '@material-ui/icons/Replay';

const QueueStatus = (props) => {
  const {queueItem} = props;


  if (!queueItem) {
    return (<Spinner/>);
  }

  if (queueItem.status === 'processing_failed') {
    return (
      <Message type="error">Fehler beim Verarbeiten. Queue-ID: {queueItem.id}</Message>
    );
  }

  if (queueItem.status === 'processed') {
    return (
      <div className="QueueStatus">
        <div style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>Status: Fertig! <ReplayIcon title="Seite neu laden" style={{marginLeft: '5px', cursor: 'pointer'}} onClick={() => {window.location.reload();}} /></div>
        <LinearProgress variant="determinate" color="primary" value={100}/>
      </div>
    );
  }

  let statusText = queueItem.status === 'upload_complete' ? 'In der Warteschlange' : 'Unbekannt';
  switch (queueItem.status_detail) {
    case 'merge':
      statusText = 'Zusammenfügen / Konvertieren';
      break;
    case 'create_thumbnail':
      statusText = 'Vorschaubild erstellen';
      break;
    case 'dash_low':
      statusText = 'Videostream: geringe Qualität';
      break;
    case 'dash_middle':
      statusText = 'Videostream: mittlere Qualität';
      break;
    case 'dash_high':
      statusText = 'Videostream: hohe Qualität';
      break;
    case 'dash_thumbnails':
      statusText = 'Videostream: Vorschau';
      break;
    case 'finished':
      statusText = 'Veröffentlichen';
      break;
    default:
      break;
  }

  return (
    <div className="QueueStatus">
      <div style={{marginBottom: '10px'}}>Status: {statusText}</div>
      <LinearProgress variant="determinate" color="primary" value={queueItem.percentage}/>
    </div>
  );
};

export default QueueStatus;
