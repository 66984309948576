import React from 'react';
import TextField from "@material-ui/core/TextField";
import {TextareaAutosize} from "@material-ui/core";
import cloneDeep from 'lodash/cloneDeep';
import './VideoForm.scss';
import isNumeric from "../../util/isNumeric";
import MultipleValuesInput from "../MultipleValuesInput/MultipleValuesInput";

const defaultForm = {
  name: {error: null, value: ''},
  year: {error: null, value: (new Date()).getFullYear()},
  description: {error: null, value: ''},
  people: {error: null, value: []},
  places: {error: null, value: []},
  categories: {error: null, value: []},
  tags: {error: null, value: []},
};

export function getDefaultForm() {
  return cloneDeep(defaultForm);
}

const VideoOrGalleryDetails = ({nameFieldTitle, fieldsDisabled, form, setForm}) => {

  function updateForm(name, value) {
    setForm((prevState) => {
      return {...prevState, [name]: {...prevState[name], value: value}};
    });
  }

  function nameIsValid(value) {
    return value.trim().length > 2;
  }

  function yearIsValid(value) {
    return isNumeric(value) && parseInt(value) > 1970 && parseInt(value) <= (new Date()).getFullYear();
  }

  function validateField(name, value) {
    let error = null;
    if (name === 'name') {
      error = !nameIsValid(value);
    } else if (name === 'year') {
      error = !yearIsValid(value);
    }
    setForm((prevState) => {
      return {...prevState, [name]: {...form[name], error: error}};
    });
  }

  return (
    <div className="VideoForm">
      <div className="VideoForm__field">
        <TextField
          fullWidth
          required
          error={form.name.error}
          helperText={''}
          id="name"
          label={nameFieldTitle}
          variant="outlined"
          color="secondary"
          value={form.name.value}
          disabled={fieldsDisabled}
          onInput={(e) => {updateForm('name', e.target.value);}}
          onChange={(e) => {updateForm('name', e.target.value);}}
          onBlur={(e) => {validateField('name', e.target.value);}}
        />
      </div>
      <div className="VideoForm__field">
        <TextField
          fullWidth
          required
          error={form.year.error}
          helperText={''}
          id="year"
          label="Jahr"
          variant="outlined"
          color="secondary"
          type="number"
          InputProps={{ inputProps: { min: 1970, max: (new Date().getFullYear()), step: 1 } }}
          value={form.year.value}
          disabled={fieldsDisabled}
          onInput={(e) => {updateForm('year', e.target.value);}}
          onChange={(e) => {updateForm('year', e.target.value);}}
          onBlur={(e) => {validateField('year', e.target.value);}}
        />
      </div>
      <div className="VideoForm__field">
        <div style={{ border: '1px solid rgba(0, 0, 0, 0.23)', padding: '18.5px 14px', borderRadius: '4px'}}>
          <TextareaAutosize
            className="MuiInputBase-input MuiOutlinedInput-input"
            minRows={3}
            style={{width: '100%', padding: 0}}
            placeholder="Beschreibung"
            value={form.description.value}
            disabled={fieldsDisabled}
            onChange={(e) => {updateForm('description', e.target.value);}}
          />
        </div>
      </div>

      <div className="VideoForm__field">
        <MultipleValuesInput freeFormLabel="Nicht aufgeführte Person eingeben" allowFreeform={true} useAvatars={true} autocompleteValues={['Omi', 'Opi', 'Gine', 'Claudi', 'Hebbie', 'Jan', 'Fabian', 'Jonas', 'Ben', 'Milena']} values={form.people.value} label="Personen" onUpdateValues={(value) => {updateForm('people', value)}} />
      </div>

      <div className="VideoForm__field">
        <MultipleValuesInput freeFormLabel="Nicht aufgeführten Ort eingeben" allowFreeform={true} useAvatars={false} autocompleteValues={['Heubach', 'Bubsheim', 'Gengenbach', 'Freiburg', 'Münster', 'Berlin', 'Reichenbach']} values={form.places.value} label="Orte" onUpdateValues={(value) => {updateForm('places', value)}} />
      </div>

      <div className="VideoForm__field">
        <MultipleValuesInput allowFreeform={false} useAvatars={false} autocompleteValues={['Familienleben', 'Urlaub', 'Geburtstag', 'Weihnachten', 'Veranstaltungen', 'Ausflug', 'Ostern', 'Familienfeste']} values={form.categories.value} label="Kategorien" onUpdateValues={(value) => {updateForm('categories', value)}} />
      </div>

      <div className="VideoForm__field">
        <MultipleValuesInput freeFormLabel="Neues Stichwort/Tag eingeben" allowFreeform={true} useAvatars={false} autocompleteValues={[]} values={form.tags.value} label="Tags/Stichworte" onUpdateValues={(value) => {updateForm('tags', value)}} />
      </div>
    </div>
  );
}

export default VideoOrGalleryDetails;
