import React from "react";
import Avatar from "@material-ui/core/Avatar";

export const personsWithImages = [
    'Omi',
    'Opi',
    'Gine',
    'Claudi',
    'Hebbie',
    'Jan',
    'Fabian',
    'Jonas',
    'Ben',
    'Milena',
];

const PersonAvatar = ({personName}) => {
    if (personsWithImages.indexOf(personName) > -1) {
        return (<Avatar alt={personName} className="MuiChip-avatar" src={"/static/images/avatar/"+personName+".jpg"} />);
    }
    return (
        <Avatar className="MuiChip-avatar">{personName[0]}</Avatar>
    );
};

export default PersonAvatar;
