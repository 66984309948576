import React, {useState} from 'react';
import Slider from '@material-ui/core/Slider';
import FilterHeader from './FilterHeader';
import Button from '@material-ui/core/Button';

import './FilterItemSlider.scss';

const FilterItemSlider = (props) => {

    let filterIsActive = true;

    const params = new URLSearchParams(props.queryString);
    let start = params.get(props.id + '[start]');
    if (null === start) {
        filterIsActive = false;
        start = props.values.min;
    }
    start = parseInt(start);

    let end = params.get(props.id + '[end]');
    if (null === end) {
        filterIsActive = (true === filterIsActive);
        end = props.values.max;
    }
    end = parseInt(end);

    const [sliderValues, setSliderValues] = useState([parseInt(start), parseInt(end)]);

    const updateHandler = (e, values) => {
        values = [parseInt(values[0]), parseInt(values[1])];
        if (sliderValues[0] !== values[0] || sliderValues[1] !== values[1]) {
            setSliderValues(values);
        }
    };

    let setValuesButtons = null;
    if (start !== sliderValues[0] || end !== sliderValues[1]) {
        params.set(props.id + '[start]', sliderValues[0]);
        params.set(props.id + '[end]', sliderValues[1]);

        setValuesButtons = (
            <div className="FilterItemSlider__setValues">
                <Button variant="contained" color="primary" size="small" onClick={() => {props.changeQueryHandler(params.toString());}}>Los</Button>
                <span className="reset" title="Zurücksetzen" onClick={()=>{setSliderValues([start,end])}}>&times;</span>
            </div>
        );
    }

    if (props.values.min === props.values.max) {
        return null;
    }

    let removeFilterHandler = null;
    if (filterIsActive) {
        removeFilterHandler = () => {
            params.delete(props.id + '[start]');
            params.delete(props.id + '[end]');

            props.changeQueryHandler(params.toString());
        }
    }

    return (<div className="FilterItemSlider">
        <FilterHeader title={props.title} removeFilterHandler={removeFilterHandler}/>

        <Slider
            value={sliderValues}
            max={props.values.max}
            min={props.values.min}
            step={1}
            onChange={updateHandler}
            valueLabelDisplay="auto"
        />

        <div className="FilterItemSlider__inputs">
            <input type="number" className="FilterItemSlider__start" step="1" max={props.values.max} min={props.values.min} value={sliderValues[0]} onChange={(e)=> {
                setSliderValues([parseInt(e.target.value), sliderValues[1]]);
            }}/> bis
            <input type="number" className="FilterItemSlider__end" step="1" max={props.values.max} min={props.values.min} value={sliderValues[1]} onChange={(e)=> {
                setSliderValues([sliderValues[0], parseInt(e.target.value)]);
            }}/>
            {setValuesButtons}
        </div>

    </div>);
};

export default FilterItemSlider;
