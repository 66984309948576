import React, {useEffect, useState} from 'react';
import Layout from "../Layout/Layout";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Explainer from "../../components/UI/forms/Guide/Explainer";
import SortableGrid from "../../components/SortableGrid/SortableGrid";
import ajax, {ajaxConfig} from "../../ajax/ajax";
import axios from "axios";
import {v4 as uuid} from 'uuid';
import LinearProgress from "@material-ui/core/LinearProgress";
import VideoOrGalleryDetails, {getDefaultForm} from "../../components/Upload/VideoOrGalleryDetails";
import Info from "../../components/UI/forms/Guide/Info";
import Message from "../../components/UI/Message/Message";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import './ImageUpload.scss';
import Paper from "@material-ui/core/Paper";

const ImageUpload = () => {
  const [uploads, setUploads] = useState({});
  const [uploaded, setUploaded] = useState([]);
  const [form, setForm] = useState(getDefaultForm());
  const [isPublishing, setIsPublishing] = useState(false);
  const [publishError, setPublishError] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    if (!isPublishing) {
      return;
    }
    setSuccessMessage(null);
    ajax.put('/api/v1/gallery', {
      images: uploaded.map((item) => {
        return {
          id: item.databaseId,
          name: item.title,
          isPreview: item.isPreview,
          text: item.text,
        }
      }),
      name: form.name.value,
      year: form.year.value,
      description: form.description.value,
      people: form.people.value,
      places: form.places.value,
      categories: form.categories.value,
      tags: form.tags.value,
    }, (data) => {
      setIsPublishing(false);
      setIsPublished(true);
      setPublishError(null);
      setUploads({});
      setUploaded([]);
      setForm(getDefaultForm());
      setSuccessMessage('Erfolgreich hochgeladen.')
    }, (statusText) => {
      setIsPublishing(false);
      setIsPublished(false);
      setPublishError(JSON.parse(statusText).message);
    });
  }, [isPublishing]);

  let uploadHandlerQueueRunning = false;
  const uploadHandlerQueue = [];
  function handleQueue() {
    if (uploadHandlerQueueRunning) {
      return;
    }
    if (uploadHandlerQueue.length === 0) {
      return;
    }
    uploadHandlerQueueRunning = true;
    const handlerFunction = uploadHandlerQueue.shift();
    handlerFunction().then(() => {
      uploadHandlerQueueRunning = false;
      handleQueue();
    }).catch(() => {
      uploadHandlerQueueRunning = false;
      handleQueue();
    });
  }

  function handleFileEvent(e) {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    chosenFiles.forEach((file) => {
        const localId = uuid();
        setUploads((oldState) => {
          const newState = {...oldState};
          newState[localId] = {file: file, id: localId, progress: 0, name: file.name, error: null};

          return newState;
        });
        pushToQueue(file, localId);
    });
  }

  function pushToQueue(file, localId) {
    const fileUploadHandlerFunction = () => {
      return new Promise((resolve, reject) => {
        axios.post(
          `${ajaxConfig.baseUrl}/api/v1/upload/photo`,
          file,
          {
            params: {
              fileName: file.name,
            },
            headers: { "Content-Type": "application/octet-stream", "x-auth-token": ajaxConfig.token },
            onUploadProgress: (progressEvent) => {
              setUploads((old) => {
                return {...old, [localId]: {...old[localId], progress: progressEvent.progress * 100}};
              });
            },
          }
        ).then((response) => {
          if (response.data.success) {
            const uploadedItem = {id: localId, databaseId: response.data.id, fileName: file.name, title: '', thumbnail: response.data.thumbnail};
            setUploaded((oldState) => {
              const newState = [...oldState];
              newState.push(uploadedItem);
              return newState;
            });
            setUploads((old) => {
              const newState = {...old};
              delete newState[localId];
              return newState;
            });
          }
          resolve();
        }).catch((err) => {
          setUploads((old) => {
            let message = err.response?.data?.message;
            if (!message) {
              message = err.message ?? 'Unbekannter Fehler';
            }
            return {...old, [localId]: {...old[localId], error: message}};
          });
          reject();
        });
      });
    };
    uploadHandlerQueue.push(fileUploadHandlerFunction);
    handleQueue();
  }

  function removeUploadingItem(localId) {
    setUploads((old) => {
      const newState = {...old};
      delete newState[localId];
      return newState;
    });
  }

  function retry(localId) {
    setUploads((old) => {
      const newState = {...old};
      newState[localId].progress = 0;
      newState[localId].error = null;
      return newState;
    });
    pushToQueue(uploads[localId].file, localId);
  }

  return (
    <Layout>
      <div className="ImageUpload">
        <Typography variant="h1" component="h1" gutterBottom>Galerie anlegen</Typography>
        <p style={{marginBottom: '30px'}}>Hier kannst du eine neue Bilder-Galerie auf FamilyTube einstellen.</p>

        <form>
          <Explainer number={1} title="Wähle mehrere Bild-Dateien/Fotos aus" ready done={isPublished}>
            <input id='fileUpload' type='file' multiple accept="image/*" style={{display: 'none'}} onChange={handleFileEvent} />
            <label htmlFor='fileUpload'>
              <Button variant="contained" color="secondary" size="medium" component="span">{Object.values(uploads).length > 0 || uploaded.length > 0 ? 'Weitere ' : ''}Bilder auswählen</Button>
            </label>
          </Explainer>

          <Explainer number={2} title="Lege die Reihenfolge fest und beschreibe Bilder wenn gewünscht" ready={Object.values(uploads).length > 0 || uploaded.length > 0} done={false}>
            {Object.values(uploads).map((item) => {
              return (<div key={item.id}>
                {item.error ? (<Message type="error" close={() => {removeUploadingItem(item.id);}}>{item.name}: {item.error} <Button variant="contained" size="small" onClick={() => {retry(item.id);}} style={{margin: '0 10px'}}>Erneut versuchen</Button></Message>) : (<Paper elevation={1} style={{padding: '1em', marginBottom: '10px'}}><div>Hochladen von {item.name}</div><LinearProgress value={item.progress} variant="determinate" /></Paper>)}
              </div>);
            })}
            <SortableGrid items={uploaded} setItems={setUploaded} />
          </Explainer>

          <Explainer number={3} title="Beschreibe die Galerie" ready={uploaded.length > 0} done={isPublished}>
           <VideoOrGalleryDetails form={form} setForm={setForm} fieldsDisabled={isPublishing} nameFieldTitle="Name der Galerie" />
          </Explainer>

          <Explainer number={4} title="Veröffentliche die Galerie" ready={form.name.value.trim() !== ''} last done={isPublished}>
            <Info>Die Galerie ist dann sofort online.</Info>
            {publishError ? (<Message type="error">{publishError}</Message>) : null}
            <div>
              <Button variant="contained" color="primary" size="large" onClick={() => {setIsPublishing(true);}} style={{marginTop: '15px'}} disabled={isPublishing}>Jetzt veröffentlichen</Button>
            </div>
          </Explainer>
        </form>
        {successMessage ? (<Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => {setSuccessMessage(null);}}>
          <Alert onClose={() => {setSuccessMessage(null);}} severity="success">{successMessage}</Alert>
        </Snackbar>) : null}
      </div>
    </Layout>
  );
}

export default ImageUpload;
