const defaultOptions = {
  slideshowTitle: 'Slideshow (Pfeil hoch/runter um Dauer zu ändern)',
  slideShowSpeed: 7000,
};

class PhotoSwipeSlideShow {
  constructor(lightbox, options) {
    this.options = {
      ...defaultOptions,
      ...options
    };
    this.timeout = null;
    this.lightbox = lightbox;
    this.speed = this.options.slideShowSpeed;
    this.lightbox.on('init', () => {
      this.initPlugin(this.lightbox.pswp);
    });
  }

  initPlugin(pswp) {
    const startSVG = '<svg aria-hidden="true" class="pswp__icn" viewBox="0 0 24 24" width="32" height="32">' +
      '<path d="M10 8v8l5-4-5-4zm9-5H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z" id="slideShowStartIcon"></path>' +
      '<path d="M6 6h12v12H6z" id="slideShowStopIcon" style="display:none"></path>' +
      '</svg>';

    const toggleSlideshow = () => {
      if (null !== this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
        document.getElementById('slideShowStopIcon').style.display = 'none';
        document.getElementById('slideShowStartIcon').style.display = 'inline';
      } else {
        document.getElementById('slideShowStartIcon').style.display = 'none';
        document.getElementById('slideShowStopIcon').style.display = 'inline';
        this.timeout = setTimeout(() => {
          pswp.next();
        }, this.speed);
      }
    }

    pswp.on('uiRegister', () => {
      pswp.ui.registerElement({
        name: 'slideshow-button',
        title: this.options.slideshowTitle,
        order: 10,
        isButton: true,
        html: startSVG,
        onClick: (event, el) => {
          toggleSlideshow();
        }
      });

      pswp.ui.registerElement({
        name: 'slideshow-speed',
        title: 'Slideshow Dauer',
        className: 'pswp_slideshow_speed',
        order: 11,
        isButton: false,
        onClick: (e) => {
          if (e.target.classList) {
            if (e.target.classList.contains('slower')) {
              this.setSpeed(this.speed + 1000);
            } else if (e.target.classList.contains('faster')) {
              this.setSpeed(this.speed - 1000);
            }
          }
        },
        html: `<div><span class="faster">-</span><span class="speed">${this.speed / 1000}</span><span class="slower">+</span></div>`,
      });

      pswp.events.add(document, 'keydown', (e) => {
        if (e.code === 'Space') {
          e.preventDefault();
          e.stopPropagation();
          toggleSlideshow();
        } else if (e.code === 'ArrowUp') {
          e.preventDefault();
          e.stopPropagation();
          this.setSpeed(this.speed + 1000);
        } else if (e.code === 'ArrowDown') {
          e.preventDefault();
          e.stopPropagation();
          this.setSpeed(this.speed - 1000);
        }
      });
    });

    this.lightbox.on('change', (e) => {
      if (this.timeout !== null) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          pswp.next();
        }, this.speed);
      }
    });

    this.lightbox.on('close', () => {
      clearTimeout(this.timeout);
    });
  }

  setSpeed(speed) {
    if (speed < 1000) {
      return;
    }
    this.speed = speed;
    document.querySelector('.pswp_slideshow_speed .speed').innerHTML = `${this.speed / 1000}`;
  }
}

export default PhotoSwipeSlideShow;
