import React, {useEffect, useRef} from 'react';
import {CountUp} from 'countup.js'

import './Counter.scss';

const Counter = ({videoCount, videoHours, imageCount}) => {

    const videoCountRef = useRef(null);
    const videoHoursRef = useRef(null);
    const imageCountRef = useRef(null);

    useEffect(() => {
        if (null !== videoCountRef) {
            const countUpVideoCount = new CountUp(videoCountRef.current, videoCount);
            if (!countUpVideoCount.error) {
                countUpVideoCount.start();
            } else {
                console.error(countUpVideoCount.error);
            }
        }
        if (null !== videoHoursRef) {
            const countUpVideoHours = new CountUp(videoHoursRef.current, videoHours);
            if (!countUpVideoHours.error) {
                countUpVideoHours.start();
            } else {
                console.error(countUpVideoHours.error);
            }
        }
        if (null !== imageCountRef) {
            const countUpImageCount = new CountUp(imageCountRef.current, imageCount);
            if (!countUpImageCount.error) {
                countUpImageCount.start();
            } else {
                console.error(countUpImageCount.error);
            }
        }
    }, [videoCount, videoHours, imageCount]);

    return (
        <div className="Counter">
            <div className="Counter__item Counter--videos">
                <div className="Counter__item__count" ref={videoCountRef}>0</div>
                <div className="Counter__item__label">Videos</div>
            </div>
            <div className="Counter__item Counter__item--hours">
                <div className="Counter__item__count" ref={videoHoursRef}>0</div>
                <div className="Counter__item__label">Stunden</div>
            </div>
            <div className="Counter__item Counter__item--hours">
                <div className="Counter__item__count" ref={imageCountRef}>0</div>
                <div className="Counter__item__label">Bilder</div>
            </div>
        </div>
    );
};

export default Counter;
