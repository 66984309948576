import React, {useEffect, useState} from 'react';

import DoneIcon from '@material-ui/icons/Done';
import {makeStyles} from "@material-ui/core/styles";
import {Card, CardContent, CardHeader, CardMedia, ListItem, Popover} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Typography from "@material-ui/core/Typography";
import {Delete, Edit} from "@material-ui/icons";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import {useHistory} from "react-router-dom";
import './GalleryCard.scss';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
}));

const GalleryCard = ({onDeleteGallery, id, description, title, date, thumbnailUrl}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const [deleteDialogValue, setDeleteDialogValue] = useState(null);
  const [galleryIdToDelete, setGalleryIdToDelete] = useState(null);

  useEffect(() => {
    if (galleryIdToDelete) {
      if (deleteDialogValue) {
        onDeleteGallery(id);
        setGalleryIdToDelete(null);
        setDeleteDialogValue(null);
      } else if (deleteDialogValue === false) {
        setGalleryIdToDelete(null);
        setDeleteDialogValue(null);
      }
    }
  }, [galleryIdToDelete, deleteDialogValue, onDeleteGallery, id]);

  const handleDeleteClick = () => {
    setAnchorEl(null);
    setGalleryIdToDelete(id);
  };

  const history = useHistory();
  const handleEditClick = () => {
    history.push(`/uploadGallery/edit/${id}`);
  };

  const handleDeleteClose = (newValue) => {
    setDeleteDialogValue(newValue);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  let cardContent = null;
  if (description) {
    cardContent = (
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {description}
        </Typography>
      </CardContent>
    );
  }

  return (
    <React.Fragment>
      <Card className="GalleryCard">
        <CardHeader
          avatar={
            <Avatar aria-label="Veröffentlicht" style={{backgroundColor:'#3a7626'}}>
              <DoneIcon />
            </Avatar>
          }
          action={
            <IconButton aria-label="Bearbeiten" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          }
          title={title}
          subheader={date}
        />
        {thumbnailUrl ? (
          <CardMedia
            className={classes.media}
            image={thumbnailUrl}
          />
        ) : null}
        {cardContent}
      </Card>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List component="nav" aria-label="Editieren">
          <ListItem button onClick={handleEditClick}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText primary="Editieren" />
          </ListItem>
          <ListItem button onClick={handleDeleteClick}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText primary="Löschen" />
          </ListItem>
        </List>
      </Popover>
      <ConfirmDeleteDialog
        classes={{
          paper: classes.paper,
        }}
        id="delete-confirm"
        keepMounted
        open={null !== galleryIdToDelete}
        onClose={handleDeleteClose}
        dialogContent="Soll die Galerie wirklich unwiderruflich gelöscht werden?"
      />
    </React.Fragment>
  );
}

export default GalleryCard;
