import React, {useEffect, useState} from 'react';
import Layout from '../Layout/Layout';
import VideoList from '../../components/VideoList/VideoList';
import Typography from "@material-ui/core/Typography";
import useFavorites from "../../hooks/useFavorites";
import Spinner from "../../components/UI/Spinner/Spinner";
import ajax from "../../ajax/ajax";
import Message from "../../components/UI/Message/Message";
import HomeNavigation from "../../components/NavigationBar/HomeNavigation";

const Favorites = () => {
    const [favorites, addFavorite, removeFromFavorites] = useFavorites();
    const [videos, setVideos] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {

        if (!favorites) {
            return;
        }

        const mapped = favorites.map((id) => {
            return 'ids[]='+encodeURIComponent(id);
        });

        const {cancel} = ajax.get('/api/v1/videos?'+ mapped.join('&'), (response) => {
            setVideos(response);
        }, (error, status) => {
            let errorMessage = 'Server nicht erreichbar.';
            if (error) {
                errorMessage = error;
            }

            if (status === 401) {
                errorMessage = 'Login erforderlich.';
            }

            setError(errorMessage);
        });

        return () => {
            cancel();
        }
    }, [favorites]);

    let content = <Spinner/>;
    if (error !== null) {
        content = <Message type="error">{error}</Message>;
    } else {
        if (favorites !== null) {
            if (favorites.length === 0) {
                content = 'Noch keine Favoriten.';
            } else if (videos) {
                for (let video of videos.videos) {
                    video.isFavorite = null !== favorites && favorites.indexOf(video.id) > -1;
                    video.setIsFavorite = () => {
                        addFavorite(video.id);
                    };
                    video.removeFromFavorites = () => {
                        removeFromFavorites(video.id);
                    };
                }
                content = <VideoList {...videos} />;
            }
        }
    }

    return (
        <Layout additionalNavigation={<HomeNavigation/>}>
            <Typography variant="h1" component="h1" gutterBottom>
                Favoriten
            </Typography>
            {content}
        </Layout>
    );

};

export default Favorites;
