import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from "react";
import './Info.scss';

const Info = ({highlight, children}) => {
  const classes = ['Info'];
  if (highlight) {
    classes.push('Info--highlight');
  }
  return (
    <React.Fragment>
      <div className={classes.join(' ')}>
        <InfoOutlinedIcon/>
        <div className="Info__message">
          {children}
        </div>
      </div>
      <div className="Info__spacer"/>
    </React.Fragment>
  )
}

export default Info;
