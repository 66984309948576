import React, {useEffect, useState} from 'react';
import Counter from '../../components/Counter/Counter';
import ajax from '../../ajax/ajax';
import Message from '../../components/UI/Message/Message';
import Layout from '../Layout/Layout';
import VideoList from '../../components/VideoList/VideoList';
import useVideos from '../../hooks/useVideos';
import Typography from "@material-ui/core/Typography";
import HomeNavigation from "../../components/NavigationBar/HomeNavigation";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import useGalleries from "../../hooks/useGalleries";
import GalleriesList from "../../components/Gallery/GalleriesList";

const HomeCounter = () => {

    const [error, setError] = useState(null);

    const [stats, setStats] = useState({
        videoHours: 0,
        videoCount: 0,
        imageCount: 0,
    });

    useEffect(() => {
        const {cancel} = ajax.get('/api/v1/stats', (response) => {
            setStats({
                videoHours: response.stats.hours,
                videoCount: response.stats.count,
                imageCount: response.stats.images,
            });
        }, () => {
            setError('Statistiken konnten nicht geladen werden.');
        });

        return () => {
            cancel();
        }
    }, []);

    let content =  (<Counter {...stats}/>);
    if (null !== error) {
        content = (<Message type="error">{error}</Message>);
    }

    return content;
};

const HomeRecentlyAdded = () => {
    const [videos] = useVideos('?sort=recently-added&size=5');
    const [loading, error, galleries] = useGalleries('?sort=recently-added&size=5');
    return (
      <div style={{marginBottom: '30px'}} >
          <Typography variant="h2" component="h2" gutterBottom>Zuletzt hinzugefügt</Typography>
          <Typography variant="h3" component="h3" gutterBottom>Videos</Typography>
          <VideoList {...videos} />
          <Typography variant="h3" component="h3" gutterBottom>Galerien</Typography>
          <GalleriesList galleries={galleries.galleries} loading={loading} error={error} />
      </div>
    );
};

const HomeRandom = () => {
    const [videos] = useVideos('?sort=random&size=5');
    const [loading, error, galleries] = useGalleries('?sort=random&size=5');
    return (
        <div style={{marginBottom: '30px'}} >
            <Typography variant="h2" component="h2" gutterBottom>Lucky Selection</Typography>
            <Typography variant="h3" component="h3" gutterBottom>Videos</Typography>
            <VideoList {...videos} />
            <Typography variant="h3" component="h3" gutterBottom>Galerien</Typography>
            <GalleriesList galleries={galleries.galleries} loading={loading} error={error} />
        </div>
    );
};

const Home = () => {
    let history = useHistory();

    return (<Layout additionalNavigation={<HomeNavigation/>}>
        <HomeCounter />
        <div style={{display: 'flex', justifyContent: 'center', margin: '30px 0', gap: '15px'}}>
            <Button onClick={() => {history.push('/videos')}} variant="outlined" color="secondary" size="large" endIcon={<ArrowForwardIcon />}>Zu den Videos</Button>
            <Button onClick={() => {history.push('/galleries')}} variant="outlined" color="secondary" size="large" endIcon={<ArrowForwardIcon />}>Zu den Bildern</Button>
        </div>
        <HomeRecentlyAdded />
        <HomeRandom />
    </Layout>);
};

export default Home;
