import React from "react";
import DoneIcon from '@material-ui/icons/Done';
import './Explainer.scss';

const Explainer = (props) => {
  const classNames = ['Explainer'];
  if (props.last) {
    classNames.push('Explainer--last');
  }
  if (props.done) {
    classNames.push('Explainer--done');
  }
  let content = (<div className="Explainer__content Explainer__content--empty"></div>);
  if (props.ready) {
    content = (
      <div className="Explainer__content">
        {props.children}
      </div>
    );
  } else {
    classNames.push('Explainer--disabled');
  }
  return (
    <div className={classNames.join(' ')}>
      <div className="Explainer__header">
        <div className="Explainer__header__number">
          {props.done ? (<DoneIcon />) : props.number}
        </div>
        <div className="Explainer__header__title">
          {props.title}
        </div>
      </div>
      {content}
    </div>
  );
}

export default Explainer;
