import React from 'react';
import LinkStyleButton from '../UI/Button/LinkStyleButton';

const FilterHeader = (props) => {

    let removeFilter = null;
    if (props.removeFilterHandler) {
        removeFilter = (<LinkStyleButton className="remove" onClick={(e) => {e.preventDefault(); props.removeFilterHandler();}}>Filter zurücksetzen</LinkStyleButton>);
    }

    return (
        <div className="FilterItem__name">
            {props.title}
            {removeFilter}
        </div>
    );
}

export default FilterHeader;