import React from 'react';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import VolumeIcon from "@material-ui/icons/VolumeUp";
import MuteIcon from "@material-ui/icons/VolumeMute";
import ControlIcon from "./ControlIcon";
import './Volume.scss';

const VolumeSlider = withStyles({
    root: {
        color: '#fff',
    },
    thumb: {
        backgroundColor: '#fff',
        '&:hover': {
            boxShadow: '0px 0px 0px 8px rgba(255, 255, 255, 0.16)',
        },
        '&:active': {
            boxShadow: '0px 0px 0px 14px rgba(255, 255, 255, 0.16)',
        },
    },
})(Slider);

const Volume = ({volume, muted, setVolumeHandler, toggleMute, setVolumeInLocalStorage}) => {
    function handleVolumeChange(volume) {
        if (volume === 0) {
            toggleMute(true);
            return;
        } else {
            toggleMute(false);
        }
        setVolumeHandler(volume);
    }

    return (
        <div className="Volume">
            <Grid container spacing={1}>
                <Grid item>
                    {muted ? <ControlIcon clickHandler={() => {toggleMute();}}><MuteIcon/></ControlIcon>:<ControlIcon clickHandler={() => {toggleMute();}}><VolumeIcon/></ControlIcon>}
                </Grid>
                <Grid item xs style={{alignSelf: 'center', display: 'flex'}}>
                    <VolumeSlider value={muted ? 0 : volume} defaultValue={1} min={0} max={1} step={0.1} onChange={(e, value) => {handleVolumeChange(value)}} onChangeCommitted={(e, value) => {setVolumeInLocalStorage(value);}} aria-labelledby="Lautstärke" />
                </Grid>
            </Grid>
        </div>
    );
};

export default Volume;
