import React, {useEffect, useState} from 'react';
import Scrubber from "./Scrubber";
import { makeStyles } from '@material-ui/core/styles';
import ProgressBarThumbnail from "./ProgressBarThumbnail";
import {isMobile} from "../../../../util/breakpoints";
import isTouchDevice from "../../../../util/isTouchDevice";

const useStyles = makeStyles({
    root: {
        position: 'relative'
    }
});

const touchDevice = isTouchDevice();

const ProgressBar = ({positionPlayHead, positionBufferHead, containerWidth, onPreviewSeek, movePlayHead, markers, duration, thumbnail, setHidingControlsPrevented}) => {
    const [handlePosition, setHandlePosition] = useState(positionPlayHead);
    const [useInternalHandlePosition, setUseInternalHandlePosition] = useState(false);
    const [allowThumbnail, setAllowThumbnail] = useState(!touchDevice);

    const handleScrubStart = (value) => {
        setHandlePosition(value);
        setUseInternalHandlePosition(true);
        setHidingControlsPrevented(true);
    };

    const handleScrubEnd = (value) => {
        if (touchDevice) {
            setAllowThumbnail(false);
        }
        movePlayHead(value);
        setHandlePosition(value);
        setTimeout(() => {
            setUseInternalHandlePosition(false);
        }, 500);

        setHidingControlsPrevented(false);
    };

    const handleScrubChange = (value) => {
        setAllowThumbnail(true);
        setHandlePosition(value);
        onPreviewSeek(value);
    };

    const seekPreviewHandler = (value) => {
        onPreviewSeek(value);
    };

    const mouseLeaveHandler = () => {
        onPreviewSeek(null);
    }

    const scrubberMarkers = [];
    if (!isNaN(duration)) {
        for (let marker of markers) {
            scrubberMarkers.push({...marker, percentage: marker.time});
        }
    }

    const classes = useStyles();
    const handlePositionToUse = useInternalHandlePosition ? handlePosition : positionPlayHead;

    return (
        <div className={classes.root}>
            <ProgressBarThumbnail thumbnail={allowThumbnail ? thumbnail : null} duration={duration} containerWidth={containerWidth} />
            <Scrubber min={0} max={duration} value={handlePositionToUse} bufferPosition={positionBufferHead} onScrubStart={handleScrubStart} onScrubEnd={handleScrubEnd} onScrubChange={handleScrubChange} onSeekPreview={seekPreviewHandler} mouseLeaveHandler={mouseLeaveHandler} markers={scrubberMarkers}/>
        </div>
    );
};

export default ProgressBar;
