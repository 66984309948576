import React from 'react';
import Fab from '@material-ui/core/Fab';
import PlayIcon from "@material-ui/icons/PlayArrow";
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        width: 200,
        height: 200,
        marginLeft: -100,
        marginTop: -100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const BiggerFab = withStyles({
    root: {
        width: 75,
        height: 75
    }
})(Fab);

const BiggerPlayIcon = withStyles({
    root: {
        width: 40,
        height: 40
    }
})(PlayIcon);

const BigPlayButton = ({show, play}) => {
    const classes = useStyles();

    if (!show) {
        return null;
    }

    return (
        <div className={classes.root}>
            <BiggerFab color="primary" aria-label="Play" size={"large"} onClick={play}>
                <BiggerPlayIcon />
            </BiggerFab>
        </div>
    );
};

export default BigPlayButton;
