class PhotoSwipeShowHideControls {
  constructor(lightbox, options) {
    this.options = {...options};
    this.lightbox = lightbox;
    this.lightbox.on('init', () => {
      this.initPlugin(this.lightbox.pswp);
    });
  }

  initPlugin(pswp) {
    this.timeout = null;
    pswp.on('uiRegister', () => {
      this.timeout = setTimeout(() => {
        this.hideControls();
      }, 2000);
      pswp.events.add(document, 'mousemove', (e) => {
        clearTimeout(this.timeout);
        this.showControls();
        this.timeout = setTimeout(() => {
          this.hideControls();
        }, 2000);
      });
    });

    this.lightbox.on('close', () => {
      clearTimeout(this.timeout);
      this.showControls();
    });
  }

  hideControls() {
    this.lightbox.pswp.container.parentElement.classList.add('pswpHideControls');
  }

  showControls() {
    this.lightbox.pswp.container.parentElement.classList.remove('pswpHideControls');
  }
}

export default PhotoSwipeShowHideControls;
