import React from 'react';
import './MultipleValuesInput.scss';
import Chip from "@material-ui/core/Chip";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TextInputWithConfirmCancel from "../UI/forms/Input/TextInputWithConfirmCancel";
import PersonAvatarFull from "../PersonAvatar/PersonAvatarFull";
const MultipleValuesInput = ({allowFreeform, label, values, onUpdateValues, autocompleteValues, useAvatars, freeFormLabel}) => {

  const autocompleteValuesFiltered = autocompleteValues.filter((candidate) => {
    return !values.includes(candidate);
  });

  function addValue(value) {
    if (values.includes(value)) {
      return;
    }

    if (!allowFreeform && !autocompleteValues.includes(value)) {
      return;
    }

    onUpdateValues([...values, value]);
  }

  function removeValue(value) {
    const newValues = [...values];
    const index = newValues.indexOf(value);
    if (index > -1) {
      newValues.splice(index, 1);
    }

    onUpdateValues(newValues);
  }

  function renderChip(value, props, useAvatar) {
    if (useAvatar) {
      return (<PersonAvatarFull personName={value} {...props} />);
    }

    return (<Chip variant="outlined" size="medium" label={value} {...props} />);
  }

  let noneSelectedText = 'Nichts ausgewählt: Bitte per Klick hinzufügen' + (allowFreeform ? ' oder eingeben.' : '.');
  if (autocompleteValuesFiltered.length === 0) {
    noneSelectedText = 'Nichts ausgewählt' + (allowFreeform ? ', hier eingeben.' : '.');
  }

  let selected = (<div className="MultipleValuesInput__selected__noValue">{noneSelectedText}</div>);
  if (values.length > 0) {
    selected = (
      <React.Fragment>
        {values.map((value) => {
          return (<span key={value} className="MultipleValuesInput__selected__value">
            {renderChip(value, {onDelete: () => {removeValue(value);}}, useAvatars)}
          </span>);
        })}
      </React.Fragment>
    );
  }

  let autocompletePart = null;
  if (autocompleteValuesFiltered.length > 0) {
    autocompletePart = (
      <div className="MultipleValuesInput__add">
        <div className="MultipleValuesInput__add__label">Per Klick hinzufügen</div>
        {autocompleteValuesFiltered.map((autocompleteValue) => {
          return (
            <span key={autocompleteValue} className="MultipleValuesInput__add__value" onClick={() => {addValue(autocompleteValue);}}>
                {renderChip(autocompleteValue, {icon: (<AddCircleIcon />)}, false)}
              </span>);
        })}
      </div>
    );
  }

  return (
    <div className="MultipleValuesInput">
      <div className="MultipleValuesInput__select">
        <div className="MultipleValuesInput__selected">
          <div className="MultipleValuesInput__label">{label}</div>
          {selected}
          {allowFreeform ? (
            <TextInputWithConfirmCancel label={freeFormLabel ? freeFormLabel : 'Eigener Wert'} onCancel={() => {}} initialValue="" confirmLabel="Übernehmen" onConfirm={(value) => {addValue(value); return true;}} edit={false} />
          ) : null}
        </div>
        {autocompletePart}
      </div>
    </div>
  );
}

export default MultipleValuesInput;
