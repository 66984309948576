import React from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import {useRouteMatch} from "react-router";
import {Link} from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from '@material-ui/core/styles';
import PersonAvatar from "../PersonAvatar/PersonAvatar";
import {ListItemAvatar} from "@material-ui/core";

function ListItemLink(props) {
    let match = useRouteMatch({
        path: props.to,
        exact: props.exact
    });
    const LinkComponent = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);
    return <ListItem button component={LinkComponent} {...props} selected={match} />;
}

const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const HomeNavigation = () => {
    const [openCategories, setOpenCategories] = React.useState(false);
    const [openPeople, setOpenPeople] = React.useState(false);

    const handleClickCategories = () => {
        setOpenCategories(!openCategories);
    };

    const handleClickPeople = () => {
        setOpenPeople(!openPeople);
    };

    const classes = useStyles();

    const categories = [
        'Familienleben',
        'Weihnachten',
        'Ausflug',
        'Urlaub',
        'Familienleben',
        'Ostern',
        'Veranstaltungen',
        'Geburtstag',
        'Familienfeste',
    ];

    const people = [
        'Omi',
        'Opi',
        'Gine',
        'Claudi',
        'Hebbie',
        'Jan',
        'Fabian',
        'Jonas',
        'Ben',
        'Milena',
    ];

    return (
        <React.Fragment>
            <Divider />
            <ListItem button onClick={handleClickCategories}>
                <ListItemText primary="Kategorien" />
                {openCategories ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openCategories} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {categories.map((category)=>{
                        const url = '/videos?categories%5B%5D='+encodeURIComponent(category);
                        return (
                            <ListItemLink to={url} className={classes.nested} key={category}>
                                <ListItemText primary={category} />
                            </ListItemLink>
                        );
                    })}
                </List>
            </Collapse>
            <ListItem button onClick={handleClickPeople}>
                <ListItemText primary="Personen" />
                {openPeople ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openPeople} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {people.map((person)=>{
                        const url = '/videos?people%5B%5D='+encodeURIComponent(person);
                        return (
                            <ListItemLink to={url} className={classes.nested} key={person}>
                                <ListItemAvatar><PersonAvatar personName={person}/></ListItemAvatar>
                                <ListItemText primary={person} />
                            </ListItemLink>
                        );
                    })}
                </List>
            </Collapse>
        </React.Fragment>
    );
};

export default HomeNavigation;