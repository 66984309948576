import React from 'react';
import {getToken} from "../../ajax/ajax";
import urlFriendlyName from "../../util/urlFriendlyName";
import './GalleriesList.scss';
import Spinner from "../UI/Spinner/Spinner";
import Message from "../UI/Message/Message";

const GalleriesList = ({galleries, loading, error}) => {
  if (loading) {
    return (<Spinner />);
  }

  if (error) {
    return (<Message type="error">Galerien konnen nicht geladen werden.</Message>);
  }

  return (
    <div className="GalleriesList">
      {galleries.map((gallery) => {
        const url = '/gallery/' + encodeURIComponent(urlFriendlyName(gallery.title)) + '_' + gallery.id.toString();

        return (
          <div className="Gallery">
            <a href={url}>
              <div className="Gallery__image">
                <img src={gallery.thumbnail+'?token='+encodeURIComponent(getToken())} alt="" loading="lazy" />
              </div>
              <div className="Gallery__title">{gallery.title}</div>
              <div className="Gallery__count">{gallery.imageCount} Bilder</div>
            </a>
          </div>
        );
      })}
    </div>
  );
}

export default GalleriesList;
