import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import {withStyles} from "@material-ui/core/styles";

const HeartIcon = withStyles({
    root: {
        backgroundColor: 'rgba(255, 255, 255, 0.02)',
        '&:hover': {
            backgroundColor: 'rgba(230, 34, 67, 0.18)',
        },
    },
})(IconButton);


const FavoriteButton = (props) => {

    const addFavorite = (e) => {
        e.preventDefault();
        e.stopPropagation();
        props.addToFavorites();
    };

    const removeFromFavorites = (e) => {
        e.preventDefault();
        e.stopPropagation();
        props.removeFromFavorites();
    };



    if (props.isFavorite) {
        return (
            <HeartIcon color="primary" aria-label="Von Favoriten entfernen" onClick={removeFromFavorites}>
                <Favorite />
            </HeartIcon>
        );
    }

    return (
        <HeartIcon color="primary" aria-label="Zu Favoriten hinzufuegen" onClick={addFavorite}>
           <FavoriteBorder/>
        </HeartIcon>
    );
};

export default FavoriteButton;