import React, {useEffect, useState} from 'react';

import DoneIcon from '@material-ui/icons/Done';
import {makeStyles} from "@material-ui/core/styles";
import {Card, CardContent, CardHeader, CardMedia, ListItem, Popover} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Typography from "@material-ui/core/Typography";
import {Delete, Edit} from "@material-ui/icons";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import QueueStatus from "./QueueStatus";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import ajax from "../../ajax/ajax";
import {useHistory} from "react-router-dom";
import './VideoCard.scss';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
}));

const VideoCard = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const [deleteDialogValue, setDeleteDialogValue] = useState(null);
  const [videoIdToDelete, setVideoIdToDelete] = useState(null);

  const [queueItem, setQueueItem] = useState(null);

  const {queueId, published, onDeleteVideo, id} = props;

  useEffect(() => {
    if (published) {
      return;
    }
    let cancelRequest;
    let intervalId;
    function getStatus() {
      cancelRequest = ajax.get('/api/v1/upload/status?id='+encodeURIComponent(queueId), (queueItemFromServer) => {
        setQueueItem(queueItemFromServer);
        if (['processing_failed', 'processed'].includes(queueItemFromServer.status)) {
          if (intervalId) {
            clearInterval(intervalId);
          }
        }
      }, () => {
        setQueueItem(null);
      }).cancel;
    }
    getStatus();
    intervalId = setInterval(() => {
      getStatus();
    }, 3000);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      if (cancelRequest) {
        cancelRequest();
      }
    };
  }, [queueId, published]);

  useEffect(() => {
    if (videoIdToDelete) {
      if (deleteDialogValue) {
        onDeleteVideo(id);
        setVideoIdToDelete(null);
        setDeleteDialogValue(null);
      } else if (deleteDialogValue === false) {
        setVideoIdToDelete(null);
        setDeleteDialogValue(null);
      }
    }
  }, [videoIdToDelete, deleteDialogValue, onDeleteVideo, id]);

  const handleDeleteClick = () => {
    setAnchorEl(null);
    setVideoIdToDelete(id);
  };

  const history = useHistory();
  const handleEditClick = () => {
    history.push(`/upload/edit/${props.id}`);
  };

  const handleDeleteClose = (newValue) => {
    setDeleteDialogValue(newValue);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  let cardContent = null;
  if (props.description || props.published === 0) {
    cardContent = (
      <CardContent>
        {props.published === 0 ? (<QueueStatus queueItem={queueItem}/>) : null}
        {props.description ? (
          <Typography variant="body2" color="textSecondary" component="p" style={{marginTop: props.published === 0 ? '15px' : ''}}>
            {props.description}
          </Typography>
        ) : null}
      </CardContent>
    );
  }

  return (
    <React.Fragment>
      <Card className="VideoCard">
        <CardHeader
          avatar={
            <Avatar aria-label="Veröffentlicht" style={{backgroundColor: props.published ? '#3a7626' : '#1a84c3'}}>
              {props.published ? (<DoneIcon />) : (<HourglassEmptyIcon />)}
            </Avatar>
          }
          action={
            <IconButton aria-label="Bearbeiten" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          }
          title={props.title}
          subheader={props.date}
        />
        {props.thumbnailUrl ? (
          <CardMedia
            className={classes.media}
            image={props.thumbnailUrl}
          />
        ) : null}
        {cardContent}
      </Card>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List component="nav" aria-label="Editieren">
          <ListItem button onClick={handleEditClick}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText primary="Editieren" />
          </ListItem>
          {published || (queueItem && (queueItem.status === 'processed' || queueItem.status === 'processing_failed')) ? (
            <ListItem button onClick={handleDeleteClick}>
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText primary="Löschen" />
            </ListItem>
          ) : (
            <ListItem button disabled>
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText primary="Löschen" />
            </ListItem>
          )}
        </List>
      </Popover>
      <ConfirmDeleteDialog
        classes={{
          paper: classes.paper,
        }}
        id="delete-confirm"
        keepMounted
        open={null !== videoIdToDelete}
        onClose={handleDeleteClose}
        dialogContent="Soll das Video wirklich unwiderruflich gelöscht werden?"
      />
    </React.Fragment>
  );
}

export default VideoCard;
