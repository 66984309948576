import React from 'react';
import {withRouter} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import InfoWithIcon from "../InfoWithIcon/InfoWithIcon";
import YearIcon from "@material-ui/icons/CalendarToday";
import PinIcon from "@material-ui/icons/Room";
import PersonAvatar from '../PersonAvatar/PersonAvatar';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    people: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        marginTop: theme.spacing(3),
        '& > *': {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    infoIcons: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        marginTop: theme.spacing(2),
        fontSize: '1.4rem',
        '& > *': {
            marginRight: theme.spacing(2),
        },
    },
    infoIcon:  { fontSize: '1.6rem',  verticalAlign: 'top', marginRight: theme.spacing(0.5)}
}));

const GalleryDetails = ({gallery}) => {
    const classes = useStyles();

    let places = null;
    if (gallery.places && gallery.places.length > 0 ) {
        places = (<InfoWithIcon icon={(<PinIcon className={classes.infoIcon}/>)}>{gallery.places.join(', ')}</InfoWithIcon>);
    }

    let people = null;
    if (gallery.people && gallery.people.length > 0) {
        people = (
            <div className={classes.people}>
                {gallery.people.map((person) => {return (
                    <Chip key={person} avatar={ <PersonAvatar personName={person}/>} label={person} size="medium"/>
                );})}
            </div>
        );
    }

    return (
        <div className="GalleryDetails">
            <Typography variant="h1" component="h1" gutterBottom>
                { gallery.title }
            </Typography>
            <div className={classes.infoIcons}>
                <InfoWithIcon icon={(<YearIcon className={classes.infoIcon}/>)}>{gallery.year}</InfoWithIcon>
                {places}
            </div>
            {people}
            {gallery.description && gallery.description !== '' ? <p>{ gallery.description }</p> : null}
        </div>
    );
};

export default withRouter(GalleryDetails);
