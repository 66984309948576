import React from 'react';
import VideoTeaserItem from '../Video/VideoTeaserItem/VideoTeaserItem';
import Spinner from '../../components/UI/Spinner/Spinner';
import Message from '../UI/Message/Message';

import './VideoList.scss';

const VideoList = (props) => {

    const videos = (
        <div className="VideoList">
            {props.videos.map((video) => {
                return (<VideoTeaserItem {...video} key={video.id} />)
            })}
            {props.videos.length === 0 ? <div>Es wurden keine Videos gefunden.</div> : null}
        </div>
    );

    if (props.error) {
        return (<Message type="error">Videos konnen nicht geladen werden.</Message>);
    }

    return (
        <React.Fragment>
            {props.loading ? <Spinner/> : videos}
        </React.Fragment>
    );
};

export default VideoList;
