import React, {useState, useEffect} from 'react';
import Layout from '../Layout/Layout';
import GalleryDetails from '../../components/Gallery/GalleryDetails';
import Spinner from '../../components/UI/Spinner/Spinner';
import Message from "../../components/UI/Message/Message";
import {useParams} from 'react-router-dom';
import ajax from '../../ajax/ajax';
import './GalleryDetailPage.scss';
import GalleryImages from "../../components/Gallery/GalleryImages";

const GalleryDetailPage = () => {
    const [gallery, setGallery] = useState(null);
    const [error, setError] = useState(null);

    const {galleryString} = useParams();
    const galleryId = galleryString.split('_').pop();

    useEffect(() => {
        const {cancel} = ajax.get('/api/v1/gallery/'+encodeURIComponent(galleryId), (response) => {
            setGallery(response.gallery);
        }, (error) => {
            setError(error);
        });

        return () => {
            cancel();
        }
    }, [galleryId]);

    if (null !== error) {
        return (<Layout>{<Message type="error">{error.toString()}</Message>}</Layout>);
    }

    let content = (<Spinner/>);
    if (null !== gallery) {
        content = (
          <React.Fragment>
              <div className="GalleryDetailPage">
                  <div className="GalleryDetails" style={{marginBottom: '30px'}}>
                      <GalleryDetails gallery={gallery} />
                  </div>
                  <GalleryImages images={gallery.images} />
              </div>
          </React.Fragment>
        );
    }

    return (
        <Layout closeMenuAsDefault={true}>
            {content}
        </Layout>
    );
};

export default GalleryDetailPage;
