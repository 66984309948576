import React from "react";
import {withStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

const ControlIconButton = withStyles({
    root: {
        width: 35,
        height: 35,
        padding: 6,
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
        '&:active': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
})(IconButton);

const ControlIcon = ({clickHandler, children}) => {
    return (
        <ControlIconButton color="inherit" onClick={clickHandler}>{children}</ControlIconButton>
    );
};

export default ControlIcon;
