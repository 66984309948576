import React from 'react';
import Spinner from '../../components/UI/Spinner/Spinner';
import Message from '../UI/Message/Message';

import './MoreVideosList.scss';
import Duration from "../Video/VideoDuration";
import urlFriendlyName from "../../util/urlFriendlyName";
import {getToken} from "../../ajax/ajax";

const MoreVideosTeaserItem = (props) => {

    const url = '/video/' + encodeURIComponent(urlFriendlyName(props.title)) + '_' + props.id.toString();

    return (
        <a className="MoreVideosTeaserItem" href={url}>
            <div className="MoreVideosTeaserItem__image">
                <div>
                    <img src={props.thumbnail + '&token='+encodeURIComponent(getToken())} alt=""/>
                    <div className="MoreVideosTeaserItem__duration"><Duration seconds={props.duration}/></div>
                </div>
            </div>
            <div className="MoreVideosTeaserItem__body">
                <div className="MoreVideosTeaserItem__title" data-title={props.title}>{props.title}</div>
                <div className="MoreVideosTeaserItem__year">{props.year.join('/')}</div>
            </div>
        </a>
    );
};


const MoreVideosList = (props) => {

    if (props.videos.length === 0) {
        return null;
    }
    const videos = (
        <div className="MoreVideosList">
            {props.videos.map((video) => {
                return (<MoreVideosTeaserItem {...video} key={video.id}/>)
            })}
        </div>
    );

    if (props.error) {
        return (<Message type="error">Videos konnen nicht geladen werden.</Message>);
    }

    return (
        <React.Fragment>
            {props.loading ? <Spinner/> : videos}
        </React.Fragment>
    );
};

export default MoreVideosList;
