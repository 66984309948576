import React, {useEffect, useRef, useState} from 'react';
import './Controlbar.scss';
import PlayPauseButton from "./PlayPauseButton";
import TimeCodeDisplay from "./TimeCodeDisplay";
import Volume from "./Volume";
import FullScreen from "./FullScreen";
import Quality from "./Quality";
import ProgressBar from "./ProgressBar";

const Controlbar = ({
  hide, setHidingControlsPrevented,
  isPaused, togglePlayPause,
  durationFormatted, timePlayedFormatted,
  volume, muted, setVolumeHandler, toggleMute, setVolumeInLocalStorage,
  fullscreen, toggleFullscreen,
  qualityOptions,
  positionPlayHead, positionBufferHead, duration, movePlayHead, onPreviewSeek, thumbnail, markers
}) => {
  const [containerWidth, setContainerWidth] = useState(2000);
  const barRef = useRef(null);
  useEffect(() => {
    if (barRef.current) {
      setContainerWidth(barRef.current.getBoundingClientRect().width);
    }
    function resizeHandler(e) {
      if (barRef.current) {
        setContainerWidth(barRef.current.getBoundingClientRect().width);
      }
    }
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    }
  }, [barRef]);

  const progressProps = {
    positionPlayHead, positionBufferHead, onPreviewSeek, movePlayHead, markers, duration, thumbnail, setHidingControlsPrevented
  }

  const classNames = ['Controlbar'];
  if (hide) {
    classNames.push('Controlbar--hidden');
  }

  return (
    <div className={classNames.join(' ')} ref={barRef}>
      <div className="Controlbar__top">
        <div className="Controlbar__left">
          <PlayPauseButton isPaused={isPaused} togglePlayPause={togglePlayPause} />
          <TimeCodeDisplay durationFormatted={durationFormatted} timePlayedFormatted={timePlayedFormatted}/>
        </div>
        <div className="Controlbar__right">
          <Volume volume={volume} muted={muted} setVolumeHandler={setVolumeHandler} toggleMute={toggleMute} setVolumeInLocalStorage={setVolumeInLocalStorage}/>
          <FullScreen fullscreen={fullscreen} toggleFullscreen={toggleFullscreen}/>
          <Quality qualityOptions={qualityOptions} setHidingControlsPrevented={setHidingControlsPrevented} />
        </div>
      </div>
      <div className="Controlbar__progress">
        <ProgressBar {...progressProps} containerWidth={containerWidth} />
      </div>
    </div>
  );
}

export default Controlbar;
