import React, {useEffect, useState} from 'react';
import Layout from '../Layout/Layout';
import Filter from '../../components/Filter/Filter';
import {useLocation, useHistory} from 'react-router-dom';
import useGalleries from '../../hooks/useGalleries';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import './Galleries.scss';
import {isMobileOrTablet} from "../../util/breakpoints";
import FilterListIcon from '@material-ui/icons/FilterList';
import Spinner from "../../components/UI/Spinner/Spinner";
import Message from "../../components/UI/Message/Message";
import GalleriesList from "../../components/Gallery/GalleriesList";

const Videos = () => {
    const location = useLocation();
    const queryString = location.search;
    const [loading, error, galleryResponse, setQueryString, usedQueryString] = useGalleries(queryString);
    const [sidebarShown, setSidebarShown] = useState(!isMobileOrTablet());

    const history = useHistory();

    useEffect(() => {
      const handler = () => {
        window.scrollPositions = window.scrollPositions || {};
        window.scrollPositions.galleries = window.pageYOffset;
        window.scrollPositions.galleriesQuery = usedQueryString;
      }
      document.addEventListener('scroll', handler);

      return () => {
        document.removeEventListener('scroll', handler);
      }
    }, [usedQueryString]);

    useEffect(() => {
      if (!Array.isArray(galleryResponse.galleries) && galleryResponse.galleries.length === 0) {
        return;
      }
      if (window.scrollPositions && window.scrollPositions.galleries && window.scrollPositions.galleriesQuery === usedQueryString) {
        window.scroll(0, window.scrollPositions.galleries);
      } else {
        window.scrollPositions = window.scrollPositions || {};
        window.scroll(0, 0);
        window.scrollPositions.galleries = 0;
        window.scrollPositions.galleriesQuery = usedQueryString;
      }
    }, [galleryResponse, usedQueryString]);

    const changeQueryHandler = (newQueryString) => {
        if (newQueryString.charAt(0) !== '?') {
            newQueryString = '?' + newQueryString;
        }
        history.push(location.pathname + newQueryString);
        setQueryString(newQueryString);
    };

    const additionalSidebar = (
        <React.Fragment>
          <Divider/>
          {galleryResponse.filter ? <Filter filter={galleryResponse.filter} queryString={galleryResponse.queryString} changeQueryHandler={changeQueryHandler} /> : null}
        </React.Fragment>
    );

    const params = new URLSearchParams(queryString);
    let searchQuery = params.get('q');

    const headline = searchQuery  ? 'Suche nach ' + searchQuery : 'Bilder';

    let content = (<Spinner />);
    if (!loading) {
      if (error) {
        content = (<Message type="error">{error}</Message>);
      } else {
        content = (
          <GalleriesList galleries={galleryResponse.galleries} />
        );
      }
    }

    return (
        <Layout additionalNavigation={additionalSidebar} sidebarShown={sidebarShown} setSidebarShown={setSidebarShown} sidebarScrollTo={isMobileOrTablet() ? ".Filter" : undefined}>
          <div className="Galleries" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '0.5em'}}>
            <div>
              <Typography variant="h1" component="h1">
                {headline}
              </Typography>
            </div>
            {sidebarShown ? null : (<FilterListIcon onClick={() => {setSidebarShown(true);}} />)}
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
              <SortIcon />
              <div style={{marginLeft: '5px'}}>
                <Select
                  value={params.get('sort') ?? 'date-desc'}
                  onChange={(e) => {
                    params.set('sort', e.target.value);
                    changeQueryHandler(params.toString());
                  }}
                >
                  <MenuItem className="selectWithIcon" value="date-desc">Jahr <ArrowDownwardIcon /></MenuItem>
                  <MenuItem className="selectWithIcon" value="date-asc">Jahr <ArrowUpwardIcon /></MenuItem>
                  <MenuItem className="selectWithIcon" value="duration-desc">Dauer <ArrowDownwardIcon /></MenuItem>
                  <MenuItem className="selectWithIcon" value="duration-asc">Dauer <ArrowUpwardIcon /></MenuItem>
                  <MenuItem className="selectWithIcon" value="recently-added">Neueste <ArrowDownwardIcon /></MenuItem>
                </Select>
              </div>
            </div>
          </div>
          {content}
        </Layout>
    );

};

export default Videos;
