import {withRouter} from "react-router-dom";
import {Component} from "react";

class ScrollToTop extends Component {

  isRestorablePath(path) {
    return path === '/videos' ||path === '/galleries';
  }

  componentDidUpdate(prevProps) {
    if (this.props.location) {
      const pathName = this.props.location.pathname;
      if (!this.isRestorablePath(pathName) && this.props.location !== prevProps.location) {
        window.scrollTo(0, 0);
      }
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
