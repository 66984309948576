class PhotoSwipeCaptions {
  constructor(lightbox, options) {
    this.options = {...options};
    this.lightbox = lightbox;
    this.lightbox.on('init', () => {
      this.initPlugin(this.lightbox.pswp);
    });
  }

  initPlugin(pswp) {
    this.timeout = null;
    pswp.on('uiRegister', () => {
      pswp.ui.registerElement({
        name: 'custom-caption',
        order: 9,
        isButton: false,
        appendTo: 'root',
        html: 'Caption text',
        onInit: (el, pswp) => {
          pswp.on('change', () => {
            const currSlideElement = pswp.currSlide.data.element;
            let captionHTML = '';
            if (currSlideElement) {
              const hiddenCaption = currSlideElement.querySelector('.hidden-caption-content');
              if (hiddenCaption) {
                // get caption from element with class hidden-caption-content
                captionHTML = hiddenCaption.innerHTML;
              } else {
                // get caption from alt attribute
                captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
              }
            }
            el.innerHTML = captionHTML || '';
          });
        }
      });
    });
  }
}

export default PhotoSwipeCaptions;
