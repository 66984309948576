// https://codesandbox.io/s/dndkit-sortable-image-grid-py6ve
// https://codesandbox.io/s/dnd-kit-yarn-1-eelbb
import React, {useState} from 'react';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from '@dnd-kit/sortable';

import {Grid} from './Grid';
import {SortablePhoto} from './SortablePhoto';
import {Photo} from './Photo';
import {ajaxConfig, getToken} from "../../ajax/ajax";

const UploadGallery = ({items, setItems}) => {
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const activeItemIndex = items.findIndex((candidate) => {
    return activeId === candidate.id;
  });

  function onDelete(id) {
    setItems((oldState) => {
      const newState = [...oldState];
      const index = newState.findIndex((candidate) => {
        return candidate.id === id;
      });
      if (index > -1) {
        newState.splice(index, 1);
      }

      return newState;
    });
  }

  function onSelectPrimary(id) {
    setItems((oldState) => {
      const newState = [...oldState];
      for (const index in newState) {
        newState[index] = {...newState[index], isPreview: false};
      }
      const index = newState.findIndex((candidate) => {
        return candidate.id === id;
      });
      if (index > -1) {
        newState[index] = {...newState[index], isPreview: true};
      }

      return newState;
    });
  }

  function onChangeText(id, text) {
    setItems((oldState) => {
      const newState = [...oldState];
      const index = newState.findIndex((candidate) => {
        return candidate.id === id;
      });
      if (index > -1) {
        newState[index] = {...newState[index], text: text};
      }

      return newState;
    });
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <Grid columns={4}>
          {items.map((item, index) => (
            <SortablePhoto key={item.id} text={item.text} url={item.thumbnail+'?token='+encodeURIComponent(getToken())} id={item.id} index={index} onDelete={onDelete} onSelectPrimary={onSelectPrimary} isPreview={item.isPreview} onChangeText={onChangeText} />
          ))}
        </Grid>
      </SortableContext>
      <DragOverlay adjustScale={true}>
        {activeId ? (
          <Photo url={items[activeItemIndex].thumbnail+'?token='+encodeURIComponent(getToken())} index={activeItemIndex} hideText={true} />
        ) : null}
      </DragOverlay>
    </DndContext>
  );

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event) {
    const {active, over} = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((candidate) => {
          return active.id === candidate.id;
        });
        const newIndex = items.findIndex((candidate) => {
          return over.id === candidate.id;
        });

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }
};

export default UploadGallery;
