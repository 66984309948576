import React from 'react';
import FilterItemSlider from './FilterItemSlider';
import FilterItemTerms from './FilterItemTerms';

import './Filter.scss';
import Typography from "@material-ui/core/Typography";

const Filter = (props) => {

    const filterItems = props.filter.map((filter) => {
        let inner = null;
        if (filter.type === 'slider') {
            inner = (<FilterItemSlider {...filter} queryString={props.queryString} changeQueryHandler={props.changeQueryHandler} />);
        } else if (filter.type === 'termsWithAnd' || filter.type === 'termsWithOr') {
            inner = (<FilterItemTerms {...filter} queryString={props.queryString} changeQueryHandler={props.changeQueryHandler} />);
        }

        const className = 'FilterItem FilterItem--' + filter.type;

        return (
            <div className={className} key={filter.id}>
                {inner}
            </div>
        );        
    });

    return (
        <div className="Filter">
            <Typography variant="h3" component="h3" gutterBottom>Auswahl filtern</Typography>
            {filterItems}
        </div>
    );

};

export default Filter;