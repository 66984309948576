import React from 'react';
import PlayIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import './PlayStateIndicator.scss';

const PlayStateIndicator = ({show, isPLaying}) => {
    const classes = ['PlayStateIndicator'];
    if (show) {
      if (isPLaying) {
          classes.push('PlayStateIndicator--playing');
      } else {
          classes.push('PlayStateIndicator--paused');
      }
    }

    return (
        <div className={classes.join(' ')}>
            <div className="PlayStateIndicator__play">
                <PlayIcon />
            </div>
            <div className="PlayStateIndicator__pause">
                <PauseIcon />
            </div>
        </div>
    );
};

export default PlayStateIndicator;
