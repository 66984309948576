import React from 'react';

import './LinkStyleButton.scss';

const LinkStyleButton = (props) => {
    const onClick = undefined === props.onClick ? null : props.onClick;

    const classNames = ['LinkStyleButton'];
    if (undefined !== props.className) {
        classNames.push(props.className);
    }

    return (<button className={classNames.join(' ')} onClick={onClick}>{props.children}</button>);
}

export default LinkStyleButton;