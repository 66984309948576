const ajaxConfig = {
      token: null,
      baseUrl: 'production' === process.env.NODE_ENV ? window.API_URL : 'http://localhost:8080',
};

const setToken = (token) => {
    ajaxConfig.token = token;
};

export const getToken = () => {
    return ajaxConfig.token;
};

export const getAjaxConfig = () => {
    return ajaxConfig;
};

const makeRequest = (method, url, successCallback, errorCallback, data) => {
    const xhr = new XMLHttpRequest();
    const listener = () => {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                if (successCallback) {
                    successCallback(JSON.parse(xhr.responseText), xhr);
                }
            } else {
                if (errorCallback) {
                    errorCallback(xhr.statusText, xhr.status, xhr);
                }
            }
        }
    };
    xhr.open(method, ajaxConfig.baseUrl + url);
    if (null !== ajaxConfig.token) {
        xhr.setRequestHeader('X-AUTH-TOKEN', ajaxConfig.token);
    }
    let json = null;
    if (undefined !== data) {
        json = JSON.stringify(data);
        xhr.setRequestHeader('Content-type','application/json; charset=utf-8');
    }
    xhr.addEventListener('readystatechange', listener);
    xhr.send(json);
    const cancel = () => {
        xhr.removeEventListener('readystatechange', listener);
        xhr.abort();
    };

    return {xhr: xhr, cancel: cancel};
};

const ajax = {
    get: (url, successCallback, errorCallback) => {
        return makeRequest('GET', url, successCallback, errorCallback);
    },
    post: (url, data, successCallback, errorCallback) => {
        return makeRequest('POST', url, successCallback, errorCallback, data);
    },
    put: (url, data, successCallback, errorCallback) => {
        return makeRequest('PUT', url, successCallback, errorCallback, data);
    },
    delete: (url, successCallback, errorCallback) => {
        return makeRequest('DELETE', url, successCallback, errorCallback);
    }
};

export {setToken, ajaxConfig};
export default ajax;
